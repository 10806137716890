import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from '@appfolio/react-gears';

const ConfirmAction = ({ action, disabled, onCancel, onConfirm }) => (
  <div className="mt-4 border-top pt-2">
    <p className="js-action">
      {`Are you sure you want to ${action} these credentials?`}
    </p>
    <p>This cannot be undone.</p>
    <ButtonToolbar>
      <Button
        color="danger"
        disabled={disabled}
        className="js-confirm-btn"
        onClick={onConfirm}
      >
        Yes, {action} them
      </Button>
      <Button
        color="secondary"
        disabled={disabled}
        className="js-cancel-btn"
        onClick={onCancel}
      >
        No, don&#8217;t do that
      </Button>
    </ButtonToolbar>
  </div>
);

ConfirmAction.propTypes = {
  action: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmAction.defaultProps = {
  disabled: false,
};

export default ConfirmAction;
