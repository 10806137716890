import React from 'react';
import PropTypes from 'prop-types';

const Instructions = ({ secondaryInstructions }) => (
  <p key="verification_text">
    To help keep your account safe, we want to make sure it&apos;s really you trying to sign in.
    &nbsp;
    {secondaryInstructions}
  </p>
);

Instructions.propTypes = {
  secondaryInstructions: PropTypes.string
};

export default Instructions;
