import qs from 'qs';
import auth from './auth';
import {
  NotFoundError,
  ServerError,
  UnauthorizedError,
  VhostRequiredError,
  PasswordResetRequiredError,
  KeycloakRequiredActionsError,
} from './errors';
import helpers from './helpers';

const handleResponse = async (response) => {
  if (response.status === 204) {
    response.data = null;
    return response;
  }

  if (response.type === 'opaqueredirect') {
    response.data = null;
    return response;
  }

  try {
    response.data = response ? await response.json() : null;
  } catch (e) {
    response.data = null;
  }

  if (response.ok) return response;

  if (response.status === 401) {
    if (response.data && response.data.error === 'vhost_required') {
      throw new VhostRequiredError();
    }
    if (response.data && response.data.error === 'resolve_required_actions') {
      throw new KeycloakRequiredActionsError(response.data?.message);
    }
    if (response.data?.password_reset_required) {
      throw new PasswordResetRequiredError(response.data?.message);
    }
    throw new UnauthorizedError(response.data?.message);
  }
  if (response.status === 404) throw new NotFoundError();
  if (response.status < 500 && response.status >= 400) {
    throw Object.assign({ name: 'BadRequestError' }, response.data || {});
  }

  throw new ServerError();
};

const request = async (path, options = {}) => {
  let queryString = '';
  const method = options.method || 'GET';
  const requireEmptyBody = method === 'GET' || method === 'HEAD';
  if (requireEmptyBody && options.body) {
    queryString = `?${qs.stringify(options.body)}`;
  }

  const requestOptions = {
    method,
    headers: options.headers || { 'Content-Type': 'application/json' },
  };

  if (options.authenticated) {
    const token = auth.getToken();
    if (!token) {
      throw new UnauthorizedError('No Token Saved');
    }
    requestOptions.headers.Authorization = `Bearer ${token}`;
  }

  if (!requireEmptyBody) {
    requestOptions.body = JSON.stringify(options.body);
  }

  if (options.credentials) {
    requestOptions.credentials = options.credentials;
  }

  let apiBase = process.env.API_BASE || '';
  if (options.adminRequest) {
    apiBase = process.env.ADMIN_API_BASE;
  }
  if (options.identityRequest) {
    apiBase = process.env.IDENTITY_API_BASE;
  }
  if (options.internalRequest) {
    apiBase = process.env.INTERNAL_API_BASE;
  }
  if (options.vendorRequest) {
    // eslint-disable-next-line no-restricted-globals
    const { vhost } = helpers.parseQueryString(location?.search || '');
    if (vhost && process.env.DEPLOYMENT_ENV === 'qa') {
      apiBase = `https://${vhost}.qa.appf.io`;
    } else {
      apiBase = process.env.VENDOR_API_BASE;
    }
  }
  const url = options.fully_qualified ? path + queryString : apiBase + path + queryString;
  requestOptions.redirect = 'manual';
  const response = await fetch(url, requestOptions);
  // eslint-disable-next-line no-use-before-define
  return options.skipHandler ? response : exportFunctions.handleResponse(response);
};

const exportFunctions = {
  request,
  handleResponse,
};

export default exportFunctions;
