import React, { useState } from 'react';
import PropTypes from 'prop-types';
import idp from '../../../lib/idp';
import VerificationCodeInput from './VerificationCodeInput';
import RememberMyDevice from './RememberMyDevice';
import SignInButton from './SignInButton';
import ResendButton from './ResendButton';

const EnterVerificationSection = ({
  search,
  rememberMyDevice,
  setRememberMyDevice,
  clearError,
  showResend,
  onResend,
  submitCode,
}) => {
  const [twoFactorCode, setTwoFactorCode] = useState('');

  const displayRememberMyDevice = [
    'property',
    'internal',
    undefined
  ].includes(idp.typeFromQuery(search));

  const setValidCode = (code) => {
    clearError();
    setTwoFactorCode(code);
    if (code && !displayRememberMyDevice) {
      submitCode(code, rememberMyDevice);
    }
  };

  return (
    <>
      <p key="verification_text">
        Please enter the 6 digit code sent to your device
      </p>
      <VerificationCodeInput
        setValidCode={setValidCode}
      />
      {
        displayRememberMyDevice &&
        <RememberMyDevice
          checked={rememberMyDevice}
          onChange={setRememberMyDevice}
        />
      }
      <div className='d-flex justify-content-flex-start'>
        {
          displayRememberMyDevice &&
          <SignInButton
            key="submit_btn"
            onClick={() => submitCode(twoFactorCode, rememberMyDevice)}
            disabled={!twoFactorCode}
          />
        }
        {
          showResend &&
          <ResendButton onClick={onResend} />
        }
      </div>
    </>
  );
};

EnterVerificationSection.propTypes = {
  search: PropTypes.string,
  rememberMyDevice: PropTypes.bool,
  setRememberMyDevice: PropTypes.func,
  clearError: PropTypes.func,
  showResend: PropTypes.bool,
  onResend: PropTypes.func,
  submitCode: PropTypes.func,
};

export default EnterVerificationSection;
