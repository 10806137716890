/* eslint-disable jsx-a11y/anchor-has-content */

import PropTypes from 'prop-types';
import React from 'react';

const MobileAppBanner = ({ androidLink, iosLink }) => (
  <section className="py-5 text-center">
    <a
      aria-label="Get it on the Apple app store"
      href={iosLink}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="https://pastage.cdn.appfolio.com/appfolio/images/badges/apple_store_badge.svg"
        width="120px"
        alt="Get it on the Apple app store"
      />
    </a>
    <a
      aria-label="Get it on Google Play"
      href={androidLink}
      target="_blank"
      rel="noreferrer"
    >
      <img
        src="https://pastage.cdn.appfolio.com/appfolio/images/badges/google_store_badge.svg"
        width="150px"
        alt="Get it on Google Play"
      />
    </a>
  </section>
);

MobileAppBanner.propTypes = {
  androidLink: PropTypes.string.isRequired,
  iosLink: PropTypes.string.isRequired,
};

export default MobileAppBanner;
