import he from 'he';
import qs from 'qs';
import { deepMapKeysToCamelCase } from '@appfolio/js-data-mapper';
import auth from './auth';

const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

class FormValidator {
  constructor(validations = ['username', 'password', 'passwordConfirmation']) {
    this.validations = validations;
    this.valid = true;
    this.errors = {};
  }

  validate({ username, password, passwordConfirmation }) {
    this.valid = true;
    this.errors = {};

    if (this.validations.includes('username') && !emailRegex.test(username)) {
      this.errors.usernameError = 'Please enter a valid email address';
      this.valid = false;
    }
    if (this.validations.includes('passwordHasLetter') && !(/[a-z]/i.test(password))) {
      this.errors.passwordError = 'Password must have at least 1 letter';
      this.valid = false;
    }
    if (this.validations.includes('passwordHasNumOrSym') && !(/[0-9]/.test(password) || /[^\w]/.test(password))) {
      this.errors.passwordError = 'Password must have at least 1 number or symbol';
      this.valid = false;
    }
    if (this.validations.includes('password') && (password.length < 8 || password.length > 32)) {
      this.errors.passwordError = 'Password must be between 8 and 32 characters';
      this.valid = false;
    }
    if (this.validations.includes('passwordConfirmation') && password !== passwordConfirmation) {
      this.errors.passwordError = 'Passwords do not match';
      this.valid = false;
    }
  }
}

export default {
  parseQueryString(queryString) {
    if (!queryString) return {};
    const decodedQueryString = he.decode(queryString);
    const parsedQueryString = qs.parse(
      decodedQueryString,
      { arrayFormat: 'bracket', ignoreQueryPrefix: true },
    );
    return deepMapKeysToCamelCase(parsedQueryString);
  },
  /**
   * @param {Array} accounts - a hetergeneous array of objects
   * representing 2fa partial sessions or accessTokens
   * e.g. [{ vhost: blah }, { accessToken: fsdf.fsdf.sdf }]
   */
  accountsArrayToMap(accounts, username, password) {
    return accounts.reduce((actsMap, account) => {
      const vhost = account.vhost || auth.extractVhostAndIdp(account.accessToken).vhost;
      // eslint-disable-next-line no-param-reassign
      actsMap[vhost] = { ...account, username, password };
      return actsMap;
    }, {});
  },
  FormValidator,
  getTwoFactorToken(vhost, idpType, username) {
    const idpTypeName = idpType || 'admin';
    let vhostName = vhost;
    if (idpTypeName === 'internal' || idpTypeName === 'admin') {
      vhostName = 'login';
    }
    const uniqueUserToken = `${vhostName}:${idpTypeName}:${username}`;
    const twoFactorTokensString = window.localStorage.getItem('twoFactorTokens') || '{}';
    const twoFactorTokens = JSON.parse(twoFactorTokensString);
    return twoFactorTokens[uniqueUserToken];
  },
  setTwoFactorToken(vhost, idpType, username, twoFactorToken) {
    const idpTypeName = idpType || 'admin';
    let vhostName = vhost;
    if (idpTypeName === 'internal' || idpTypeName === 'admin') {
      vhostName = 'login';
    }
    const uniqueUserToken = `${vhostName}:${idpTypeName}:${username}`;
    const twoFactorTokensString = window.localStorage.getItem('twoFactorTokens') || '{}';
    const twoFactorTokens = JSON.parse(twoFactorTokensString);
    twoFactorTokens[uniqueUserToken] = twoFactorToken;
    window.localStorage.setItem('twoFactorTokens', JSON.stringify(twoFactorTokens));
  },
  splitNParts(s, character, limit) {
    const parts = s.split(character);
    return parts.length > limit
      ? parts
        .slice(0, limit - 1)
        .concat([parts.slice(limit - 1).join(character)])
      : parts;
  }
};
