import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import ReactDOM from 'react-dom';
import React from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';

import auth from './lib/auth';
import App from './components/App';
import shouldUseHash from './lib/hashRouterHelper';

auth.restoreToken();
document.getElementById('spinner').classList.add('fadeOut');
setTimeout(() => {
  const Router = shouldUseHash(window.location.hash) ? HashRouter : BrowserRouter;
  ReactDOM.render(<App Router={Router} />, document.getElementById('root'));
}, 305); // Wait for spinner to fade
