export function ServerError(message) {
  this.name = 'ServerError';
  this.message = message || 'There has been an error on the server';
  this.stack = (new Error()).stack;
}

ServerError.prototype = Object.create(Error.prototype);
ServerError.prototype.constructor = ServerError;

export function NotFoundError(message) {
  this.name = 'NotFoundError';
  this.message = message || 'The resource could not be found';
  this.stack = (new Error()).stack;
}

NotFoundError.prototype = Object.create(Error.prototype);
NotFoundError.prototype.constructor = NotFoundError;

export function UnauthorizedError(message) {
  this.name = 'UnauthorizedError';
  this.message = message || 'The user is not authorized';
  this.stack = (new Error()).stack;
}

UnauthorizedError.prototype = Object.create(Error.prototype);
UnauthorizedError.prototype.constructor = UnauthorizedError;

export function VhostRequiredError(message) {
  this.name = 'VhostRequiredError';
  this.message = message || 'The user is not authorized';
  this.stack = (new Error()).stack;
}

VhostRequiredError.prototype = Object.create(Error.prototype);
VhostRequiredError.prototype.constructor = VhostRequiredError;

export function PasswordResetRequiredError(message) {
  this.name = 'PasswordResetRequiredError';
  this.message = message || 'You must reset your password';
  this.stack = (new Error()).stack;
}

PasswordResetRequiredError.prototype = Object.create(Error.prototype);
PasswordResetRequiredError.prototype.constructor = PasswordResetRequiredError;

export function KeycloakRequiredActionsError(message) {
  this.name = 'KeycloakRequiredActionsError';
  this.message = message || 'There are required actions that must be resolved to access your account. Please check your email for the next steps.';
  this.stack = (new Error()).stack;
}

KeycloakRequiredActionsError.prototype = Object.create(Error.prototype);
KeycloakRequiredActionsError.prototype.constructor = KeycloakRequiredActionsError;
