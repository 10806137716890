import decode from 'jwt-decode';
import { deepMapKeysToCamelCase, deepMapKeysToSnakeCase } from '@appfolio/js-data-mapper';
import api from './api';
import helpers from './helpers';
import authorization from './authorization';
import idp from './idp';

let token;

export default {
  getToken() {
    return token;
  },
  setToken(newToken) {
    token = newToken;
  },
  logOut() {
    this.setToken(null);
    window.sessionStorage.removeItem('passportToken');
    window.sessionStorage.removeItem('authRedirect');
  },
  async logIn({ username, password, idpType, vhost, magicLinkToken, ...vendorParams }) {
    const body = {
      username,
      password,
      vhost: idpType === 'vendor' && !vhost ? 'vendor' : vhost,
      property_token_credential: magicLinkToken,
      idp_type: idpType,
      client_id: process.env.CLIENT_ID || 'change-me',
      grant_type: 'password',
      ...deepMapKeysToSnakeCase(vendorParams),
      require_reverification: true,
      sync_phone_numbers: true,
    };

    let { data } = await api.request('/oauth/token', {
      method: 'POST',
      body,
    });

    data = deepMapKeysToCamelCase(data);

    // Vhostless returns a heterogenous array of accessTokens + 2fa partial sessions
    if (data.constructor === Array) {
      // Deduplicate array by transforming to object keyed on vhost
      const dataMap = helpers.accountsArrayToMap(data, username, password);

      if (Object.keys(dataMap).length > 1) {
        return dataMap;
      }
      // Flatten object if only 1 element exists
      data = dataMap[Object.keys(dataMap)[0]];
    }

    if (data.twoFactorSession || data.reverificationRequired) {
      return {
        username,
        password,
        ...data,
      };
    }

    const { accessToken } = data;
    this.setToken(accessToken);
    window.sessionStorage.setItem('passportToken', accessToken);
    return accessToken;
  },
  async loginWithTwoFactorToken({ username, password, idpType, vhost }) {
    const twoFactorToken = helpers.getTwoFactorToken(vhost, idpType, username);
    if (!twoFactorToken) {
      return null;
    }

    const body = {
      username,
      password,
      vhost,
      idp_type: idpType,
      client_id: process.env.CLIENT_ID || 'change-me',
      grant_type: 'password',
      two_factor_token: twoFactorToken,
    };

    const { data } = await api.request('/oauth/token', {
      method: 'POST',
      body,
    });

    const accessToken = data.access_token;
    if (accessToken) {
      this.setToken(accessToken);
      window.sessionStorage.setItem('passportToken', accessToken);
    }
    return accessToken;
  },
  isLoggedIn() {
    return !!token;
  },
  restoreToken() {
    const savedToken = window.sessionStorage.getItem('passportToken');
    try {
      const payload = (savedToken && decode(savedToken)) || {};
      if (payload.exp && payload.exp * 1000 > Date.now()) {
        this.setToken(savedToken);
      } else {
        throw new Error('Expired token');
      }
    } catch (e) {
      this.logOut();
    }
  },
  async logInWithTwoFactor(fields) {
    let body = {
      two_factor_code: fields.twoFactorCode,
      two_factor_session: fields.twoFactorSession,
      username: fields.username,
      phone_number: fields.phoneNumber,
      email_2fa: fields.email2Fa,
      vhost: fields.vhost,
      idp_type: fields.idpType,
      client_id: process.env.CLIENT_ID || 'change-me',
      grant_type: 'password',
      remember_my_device: fields.rememberMyDevice,
    };

    if (fields.totpMfa) {
      body = {
        totp: fields.twoFactorCode,
        two_factor_session: fields.twoFactorSession,
        username: fields.username,
        phone_number: fields.phoneNumber,
        vhost: fields.vhost,
        idp_type: fields.idpType,
        client_id: process.env.CLIENT_ID || 'change-me',
        grant_type: 'password',
      };
    }

    const { data } = await api.request('/oauth/token', {
      method: 'POST',
      body,
    });

    const accessToken = data.access_token;
    this.setToken(accessToken);
    window.sessionStorage.setItem('passportToken', accessToken);

    const twoFactorToken = data.two_factor_token;
    if (twoFactorToken) {
      helpers.setTwoFactorToken(fields.vhost, fields.idpType, fields.username, twoFactorToken);
    }
  },
  async requestTwoFactor({ vhost, twoFactorSession, idpType, twoFactorMethod, phoneNumber }) {
    await api.request('/two_factor/send_code', {
      method: 'POST',
      body: {
        vhost,
        idp_type: idpType,
        two_factor_session: twoFactorSession,
        two_factor_method: twoFactorMethod,
        number: phoneNumber,
      },
    });
  },
  async logInToVhost() {
    const vhostAndIdpType = this.extractVhostAndIdp(token);
    const params = {
      response_type: 'code',
      client_id: idp.authCodeClientId(vhostAndIdpType.idpType),
      redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
      idp_type: vhostAndIdpType.idpType,
    };
    const authCodeResponse = await authorization.approve(params);
    const authCode = authCodeResponse.redirectURI.code;
    const vhostUrl = this.mountedVhostUrl(vhostAndIdpType);
    const url = `${vhostUrl}/oauth/login_auth_code?code=${authCode}`;
    window.location.replace(url);
  },
  async requestForgotPassword({ username, idpType }) {
    switch (idpType) {
      case 'property':
      case 'tportal':
        await api.request('/users/reset_password', {
          method: 'POST',
          body: {
            username,
            idp_type: idpType,
          },
        });
        break;
      case undefined:
      case 'admin':
        await api.request('/users/password', {
          method: 'POST',
          body: {
            user: {
              email: username,
            },
          },
          ...{ adminRequest: true },
        });
        break;
      case 'internal':
        await api.request('/users/password', {
          method: 'POST',
          body: {
            user: {
              email: username,
            },
          },
          ...{ internalRequest: true },
        });
        break;
      case 'identity':
        await api.request('/users/password', {
          method: 'POST',
          body: {
            user: {
              email: username,
            },
          },
          ...{ identityRequest: true },
        });
        break;
      case 'vendor':
        await api.request('/users/password', {
          method: 'POST',
          body: {
            user: {
              email: username,
            },
          },
          ...{ vendorRequest: true },
        });
        break;
      default:
        throw new Error(`Unsupported idpType: ${idpType}`);
    }
  },
  submitResetPassword({ password, passwordConfirmation, resetPasswordToken, options }) {
    return api.request('/users/password', {
      method: 'PUT',
      body: {
        user: {
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: resetPasswordToken,
        },
      },
      ...options,
    });
  },
  requestAccessLink({ username }) {
    return api.request('/magic_link', {
      method: 'POST',
      body: {
        email: username,
      },
      vendorRequest: true,
      skipHandler: true,
    });
  },
  extractVhostAndIdp(accessToken) {
    const payload = (accessToken && decode(accessToken)) || {};
    const { aud } = payload;
    const [idpType, vhost] = helpers.splitNParts(aud, ':', 2);
    return { vhost, idpType };
  },
  mountedVhostUrl({ vhost, idpType }) {
    const pathPrefix = idpType === 'tportal' ? '/connect' : '/api/v1';
    if (['development', 'test'].includes(process.env.NODE_ENV)) {
      return `http://${vhost}${pathPrefix}`;
    }
    return `https://${vhost}${pathPrefix}`;
  },
};
