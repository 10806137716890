import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from '@appfolio/react-gears';

const RememberMyDevice = ({ checked, onChange }) => (
  <FormGroup check>
    <Input
      type="checkbox"
      id="rememberMyDevice"
      checked={checked}
      onChange={e => onChange(e.target.checked)}
    />
    <Label check for="rememberMyDevice">Do not ask this device for a code for 30 days</Label>
  </FormGroup>
);

RememberMyDevice.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RememberMyDevice;
