import api from './api';

const callEndpoint = async (method, { id, clientId }, body = null, suffix = '') => {
  const path = clientId ? `/basic_clients/${clientId}/basic_credentials` : `/basic_credentials/${id}`;
  const { data } = await api.request(`${path}${suffix}`, {
    method,
    body,
    authenticated: true,
  });

  return data ? data.data : null;
};

export default {
  async list(clientId) {
    const credentials = await callEndpoint('GET', { clientId });
    return credentials || [];
  },
  // eslint-disable-next-line camelcase
  async create(clientId, { vhost_guid }) {
    return callEndpoint('POST', { clientId }, { vhost_guid });
  },
  async get(id) {
    return callEndpoint('GET', { id });
  },
  async rotate(id) {
    return callEndpoint('PUT', { id }, {}, '/rotate');
  },
  async destroy(id) {
    return callEndpoint('DELETE', { id });
  },
};
