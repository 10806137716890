import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Edit from './Edit';
import List from './List';
import New from './New';
import Show from './Show';

const BasicClients = ({ location }) => (
  <Switch location={location}>
    <Route exact path="/basic/clients" component={List} />
    <Route exact path="/basic/clients/new" component={New} />
    <Route exact path="/basic/clients/:id" component={Show} />
    <Route exact path="/basic/clients/:id/edit" component={Edit} />
  </Switch>
);

BasicClients.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default BasicClients;
