import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import auth from '../lib/auth';
import helpers from '../lib/helpers';
import idp from '../lib/idp';

const buildQueryString = (qs) => {
  const idpType = idp.typeFromQuery(qs);
  const vhostless = idp.vhostlessFromQuery(qs);
  const queryString = helpers.parseQueryString(qs);
  const magicLinkToken = queryString.magicLinkToken;
  const branding = queryString.branding;
  const vhost = queryString.vhost;
  let search = idpType ? `?idp_type=${idpType}` : '';
  search += `${vhostless ? '&vhostless=true' : ''}`;
  search += `${magicLinkToken ? `&magic_link_token=${magicLinkToken}` : ''}`;
  search += `${branding ? `&branding=${branding}` : ''}`;

  if (idpType === 'vendor' && process.env.DEPLOYMENT_ENV === 'qa') {
    search += `${vhost ? `&vhost=${vhost}` : ''}`;
  }
  return search;
};

const redirectUrl = (qs) => {
  const idpType = idp.typeFromQuery(qs);

  if (idpType === 'vendor') {
    return '/sign_in/request_access_link';
  }

  return '/sign_in';
};

function saveAuthRedirectDestination(location) {
  const idpTypeExists = location.search && location.search.includes('idp_type=');
  const initialPath = JSON.stringify(location);
  if (idpTypeExists) {
    if (location && location.pathname === '/authorize') {
      window.sessionStorage.setItem('authRedirect', initialPath);
    }
  } else if (location && location.pathname.includes('/application')) {
    window.sessionStorage.setItem('authRedirect', initialPath);
  }
}

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component, render, children, location, ...rest }) => {
  const renderMethods = { component, render, children };
  saveAuthRedirectDestination(location);
  return (
    <Route
      {...rest}
      render={wrappedProps => (
        auth.isLoggedIn() ?
          <Route {...renderMethods} /> :
          <Redirect to={{
            pathname: redirectUrl(wrappedProps.location.search),
            search: buildQueryString(wrappedProps.location.search),
            state: {
              from: wrappedProps.location,
            },
          }}
          />
      )}
    />
  );
};


export default ProtectedRoute;
