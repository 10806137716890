import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert, BlockPanel, Datapair } from '@appfolio/react-gears';

import basicClients from '../../lib/basicClients';
import rollbar from '../../lib/rollbar';

import Controls from './Controls';
import Credentials from './Credentials/List';
import DeleteConfirmationModal from './DeleteConfirmationModal';

class Show extends Component {
  state = {
    client: null,
    flash: null,
    saving: false,
  };

  async componentDidMount() {
    const { history, match } = this.props;
    const clientId = match.params.id;

    const { state } = history.location;

    let client = null;
    let flash = null;

    if (state && state.client) {
      client = state.client;
    } else {
      client = await basicClients.get(clientId);
    }

    if (client && state) {
      if (state.created || state.updated) {
        flash = `${state.created ? 'Created' : 'Updated'} client ${client.name}.`;
      }
    }

    // clear history state
    history.replace(history.location.pathname);

    this.setState({ client, flash });
  }

  onClickEdit = () => {
    const { history } = this.props;
    const { client } = this.state;
    history.push(`/basic/clients/${client.uuid}/edit`, { client });
  };

  onOpenDeleteModal = () => this.setState({ deleteModalOpen: true });

  onCloseDeleteModal = () => this.setState({ deleteModalOpen: false });

  delete = async () => {
    const { history } = this.props;
    const { client } = this.state;
    this.setState({ saving: true, flash: null });
    try {
      await basicClients.destroy(client.uuid);
      history.push('/basic/clients', { deleted: client });
    } catch (e) {
      this.setState({ saving: false });
      rollbar.error('Error destroying client', e);
    }
  };

  render() {
    const { client, deleteModalOpen, flash, saving } = this.state;

    if (!client) {
      return <div className="spinner" />;
    }

    return (
      <>
        {flash && (
          <Alert
            color="success"
            dismissible
            icon
          >
            {flash}
          </Alert>
        )}
        <BlockPanel
          className="mb-3"
          controls={[
            <Controls
              key="controls"
              onClickDelete={this.onOpenDeleteModal}
              onClickEdit={this.onClickEdit}
            />,
          ]}
          open
          title={client.name}
        >
          <Datapair
            className="js-id"
            label="Client UUID"
            stacked
            value={client.uuid}
          />
          <Datapair
            className="js-app"
            label="Application"
            stacked
            value={client.application.name}
          />
        </BlockPanel>
        {deleteModalOpen && (
          <DeleteConfirmationModal
            disabled={saving}
            onDelete={this.delete}
            onClose={this.onCloseDeleteModal}
            name={client.name}
          />
        )}
        <Credentials client={client} />
      </>
    );
  }
}

Show.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        client: PropTypes.shape({
          name: PropTypes.string,
          uuid: PropTypes.string,
        }),
      }),
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Show;
