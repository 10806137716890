/* eslint-disable no-console */

import Rollbar from 'rollbar';

const rollbar = new Rollbar({
  accessToken: '89362fd03a114efc9d17271cf6f739e1',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.DEPLOYMENT_ENV,
  },
  ignoredMessages: [
    'refused to evaluate a string as javascript',
  ]
});

function error(message, err) {
  console.error(err, message);
  rollbar.error(message, err);
}

function warn(message, err) {
  console.warn(err, message);
  rollbar.warn(message, err);
}

function log(message) {
  console.log(message);
  rollbar.log(message);
}

export default {
  error,
  warn,
  log,
};
