import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import idp from '../../lib/idp';
import auth from '../../lib/auth';
import helpers from '../../lib/helpers';
import { ErrorMessage, InfoMessage } from '../FlashMessage';
import ForgotPasswordForm from './ForgotPasswordForm';

class ForgotPasswordPage extends React.Component {
  state = {
    error: '',
    loading: false,
  };

  validator = new helpers.FormValidator(['username']);

  requestResetPassword = async ({ username }) => {
    const { location } = this.props;
    const idpType = idp.typeFromQuery(location.search);
    this.setState({ loading: true });
    try {
      await auth.requestForgotPassword({ username, idpType });
      this.setState({ info: 'We sent an email with instructions on how to reset your password.' });
    } catch (err) {
      this.setState({ error: err.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading } = this.state;
    const { location, match } = this.props;
    const { username } = location.state || { username: '' };

    return (
      <div className="Page">
        <h1 className="mb-4">Forgot password</h1>
        <ErrorMessage message={this.state.error} />
        <InfoMessage message={this.state.info} />
        <ForgotPasswordForm
          validator={this.validator}
          onSubmit={this.requestResetPassword}
          readOnly={loading}
          username={username}
        />
        <Link
          className="float-start"
          to={{
            pathname: '/sign_in',
            search: location.search,
            state: { from: match.url },
          }}
        >
          Back to Sign In Options
        </Link>
      </div>
    );
  }
}

ForgotPasswordPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default ForgotPasswordPage;
