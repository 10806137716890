import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BlockPanel } from '@appfolio/react-gears';

import application from '../../lib/application';
import basicClients from '../../lib/basicClients';
import rollbar from '../../lib/rollbar';

import Form from './Form';

class New extends Component {
  state = {
    apps: [],
    errors: {},
    saving: false,
  };

  async componentDidMount() {
    const apps = await application.list();
    this.setState({ apps });
  }

  save = async (e, client) => {
    const { history } = this.props;

    this.setState({ saving: true });
    try {
      const savedClient = await basicClients.create(client);
      history.push(`/basic/clients/${savedClient.uuid}`, {
        client: savedClient,
        created: true,
      });
    } catch ({ name, errors }) {
      this.setState({ saving: false, errors });
      if (name !== 'BadRequestError') {
        rollbar.error('Error creating new client', errors);
      }
    }
  };

  render() {
    const { history } = this.props;
    const { apps, errors, saving } = this.state;

    return (
      <BlockPanel open title="New Client">
        <Form
          apps={apps}
          readOnly={saving}
          onCancel={() => history.push('/basic/clients')}
          onSubmit={this.save}
          errors={errors}
        />
      </BlockPanel>
    );
  }
}

New.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default New;
