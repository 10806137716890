import React from 'react';
import PropTypes from 'prop-types';
import Instructions from './Instructions';
import TwoFactorRadioButtons from './TwoFactorRadioButtons';
import SendCodeButton from './SendCodeButton';

const PhoneCodeSection = ({ value, onChange, onSubmit }) => (
  <>
    <Instructions
      secondaryInstructions='Please choose how you would like to receive a verification code.'
    />
    <TwoFactorRadioButtons
      value={value}
      setValue={onChange}
    />
    <SendCodeButton value='Send verification code' onClick={onSubmit} />
  </>
);

PhoneCodeSection.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default PhoneCodeSection;
