import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import decode from 'jwt-decode';

import vendor from '../../../lib/vendor';
import helpers from '../../../lib/helpers';
import auth from '../../../lib/auth';
import rollbar from '../../../lib/rollbar';
import { ErrorMessage, SuccessMessage } from '../../FlashMessage';

import RequestAccessLinkForm from './RequestAccessLinkForm';

function magicLinkExpired(magicLinkTokenExpiration) {
  const now = new Date().valueOf();
  return magicLinkTokenExpiration && magicLinkTokenExpiration < now;
}

class RequestAccessLinkPage extends React.Component {
  constructor(props) {
    super(props);

    this.from = (props.location.state || { from: { pathname: '/' } }).from;
    this.queryParams = helpers.parseQueryString(props.location.search);
    this.state = {
      username: props.location.state && props.location.state.username ? props.location.state.username : '',
      error: '',
      loading: false,
      checkingMagicLink: false,
      emailSent: false,
    };
  }

  async componentDidMount() {
    const { idpType, magicLinkToken } = this.queryParams;
    if (idpType === 'vendor') {
      if (magicLinkToken) {
        await this.signupAndLoginWithMagicLink(magicLinkToken, idpType);
      }
    }
  }

  redirectTo = ({ state, ...newLocation }) => {
    const { history, location } = this.props;

    return history.replace({
      ...newLocation,
      search: location.search,
      state: { ...state, from: this.from },
    });
  }

  signupAndLoginWithMagicLink = async (magicLinkToken, idpType) => {
    this.setState({ loading: true, checkingMagicLink: true });
    try {
      await vendor.signup({ magicLinkToken });
    } catch (err) {
      if (err.status !== 422) {
        rollbar.error('RequestAccessLinkPage: Error calling signupAndLoginWithMagicLink', err);
      }
    }
    try {
      await auth.logIn({ ...this.queryParams, magicLinkToken, idpType });

      this.redirectTo({
        pathname: `${this.props.match.url}`,
      });
    } catch (err) {
      rollbar.error('RequestAccessLinkPage: Error calling auth.login', err);
      const { exp: tokenExpiration, sub } = decode(magicLinkToken);
      if (magicLinkExpired(tokenExpiration)) {
        this.setState({ error: 'Your link has expired. Please request a new Access Link.' });
        if (sub && sub.user_email) {
          this.setState({ username: sub.user_email });
        }
      } else {
        this.setState({ error: 'There was an error logging you in' });
      }
    }
    this.setState({ loading: false, checkingMagicLink: false });
  }

  validator = new helpers.FormValidator(['username']);

  requestAccessLink = async ({ username }) => {
    this.setState({ loading: true });
    try {
      const response = await auth.requestAccessLink({ username });
      if (!response.ok) {
        rollbar.log(response);
        throw new Error('RequestAccessLink returned non 2xx response');
      }
      this.setState({ emailSent: true });
    } catch (error) {
      this.setState({ emailSent: false, error: 'There was an error requesting an Access Link' });
      rollbar.warn(`RequestAccessLink: Error calling login: ${error.message}`, error);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { location, match } = this.props;
    const { emailSent, loading } = this.state;

    if (this.state.checkingMagicLink) {
      return <div className="spinner" />;
    }

    return (
      <div className="Page">
        <h1 className="mb-4">Send an Access Link</h1>
        <div className="text-start text-muted mb-4">
          Access Links are sent securely to your email.
          They give you access to your account without using a password.
        </div>
        {emailSent && <SuccessMessage message="Vendor Portal Access Link was sent to your email." />}
        <ErrorMessage message={this.state.error} />
        <RequestAccessLinkForm
          readOnly={loading}
          onSubmit={this.requestAccessLink}
          username={this.state.username}
        />
        <Link
          className="float-start my-2"
          to={{
            pathname: '/sign_in',
            search: location.search,
            state: { from: match.url },
          }}
        >
          More Sign In Options
        </Link>
      </div>
    );
  }
}

RequestAccessLinkPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default RequestAccessLinkPage;
