import React from 'react';
import { Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle } from '@appfolio/react-gears';
import application from '../lib/application';
import rollbar from '../lib/rollbar';
import ApplicationForm from './ApplicationForm';

class NewApplicationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: null,
      loading: false,
      errors: {},
    };

    this.createApp = this.createApp.bind(this);
  }

  async createApp(e, app) {
    this.setState({ loading: true });
    try {
      const { client_id: clientId } = await application.create(app);
      this.setState({ clientId, loading: false });
    } catch ({ errors }) {
      this.setState({ errors, loading: false });
      rollbar.error('NewApplicationPage: Error calling createApp', errors);
    }
  }

  render() {
    const { clientId } = this.state;

    if (clientId) {
      return <Redirect to={`/applications/${clientId}`} />;
    }

    return (
      <Card>
        <CardHeader>
          <CardTitle>New Application</CardTitle>
        </CardHeader>
        <CardBody>
          <ApplicationForm
            readOnly={this.state.loading}
            onSubmit={this.createApp}
            errors={this.state.errors}
          />
        </CardBody>
      </Card>
    );
  }
}

export default NewApplicationPage;
