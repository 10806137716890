import React from 'react';
import PropTypes from 'prop-types';
import SendCodeButton from './SendCodeButton';
import Instructions from './Instructions';

const EmailCodeSection = ({ onSubmit }) => (
  <>
    <Instructions
      secondaryInstructions='To log in, please click button to email code.'
    />
    <SendCodeButton value='Send code via email' onClick={onSubmit} />
  </>
);

EmailCodeSection.propTypes = {
  onSubmit: PropTypes.func,
};

export default EmailCodeSection;
