import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BlockPanel } from '@appfolio/react-gears';

import application from '../../lib/application';
import basicClients from '../../lib/basicClients';
import rollbar from '../../lib/rollbar';

import Form from './Form';

class Edit extends Component {
  state = {
    apps: [],
    errors: {},
    flash: null,
    saving: false,
  };

  async componentDidMount() {
    const { history, match } = this.props;
    const clientId = match.params.id;

    const { state } = history.location;
    let clientPromise;
    if (state && state.client) {
      clientPromise = Promise.resolve(state.client);
    } else {
      clientPromise = basicClients.get(clientId);
    }

    const promises = [
      clientPromise,
      application.list(),
    ];

    // clear state
    history.replace(history.location.pathname);

    try {
      const [client, apps] = await Promise.all(promises);
      this.setState({ apps, client });
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  save = async (e, client) => {
    const { history } = this.props;
    this.setState({ errors: {}, saving: true, flash: null });
    try {
      const updatedClient = await basicClients.update(client);
      history.push(`/basic/clients/${client.uuid}`, { client: updatedClient, updated: true });
    } catch ({ name, errors }) {
      this.setState({ errors });
      if (name !== 'BadRequestError') {
        rollbar.error('Error saving client', errors);
      }
    } finally {
      this.setState({ saving: false });
    }
  };

  render() {
    const { history } = this.props;
    const { apps, client, errors, saving } = this.state;

    if (!client) {
      return <div className="spinner" />;
    }

    return (
      <BlockPanel
        open
        title={client.name}
      >
        <Form
          apps={apps}
          editing
          readOnly={saving}
          onCancel={() => history.push(`/basic/clients/${client.uuid}`)}
          onSubmit={this.save}
          errors={errors}
          {...client}
        />
      </BlockPanel>
    );
  }
}

Edit.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        client: PropTypes.shape({
          name: PropTypes.string,
          uuid: PropTypes.string,
        }),
      }),
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Edit;
