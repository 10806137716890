import React from 'react';
import { FormChoice, FormRow } from '@appfolio/react-gears';
import PropTypes from 'prop-types';
import * as TwoFactorMethod from '../../../constants/TwoFactorMethod';

const TwoFactorRadioButtons = ({ value, setValue }) => (
  <FormRow
    type="radio"
    name="two_factor_method"
    onChange={setValue}
    key="two_factor_radio_btns"
  >
    <FormChoice
      checked={value === TwoFactorMethod.SMS}
      value={TwoFactorMethod.SMS}
    >
      Send code via SMS
    </FormChoice>
    <FormChoice
      checked={value === TwoFactorMethod.CALL}
      value={TwoFactorMethod.CALL}
    >
      Send code via phone call
    </FormChoice>
  </FormRow>
);

TwoFactorRadioButtons.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
};

export default TwoFactorRadioButtons;
