import React from 'react';
import { Button } from '@appfolio/react-gears';
import PropTypes from 'prop-types';

const SignInButton = ({ onClick, disabled }) => (
  <Button
    size="lg"
    type="submit"
    color="primary"
    className="px-4 my-3"
    disabled={disabled}
    onClick={onClick}
  >
    Sign In
  </Button>
);

SignInButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default SignInButton;
