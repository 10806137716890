import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormRow } from '@appfolio/react-gears';

const VerificationCodeInput = ({ setValidCode }) => {
  const [code, setCode] = useState('');

  const onChange = (e) => {
    const { value } = e.target;

    const numbers = value.replaceAll(/[^\d]/g, '');
    setCode(numbers);

    if (numbers.length === 6) {
      setValidCode(numbers);
    } else {
      setValidCode('');
    }
  };

  return (
    <FormRow
      name="verification_code"
      autoComplete="off"
      autoFocus
      value={code}
      onChange={onChange}
      label="Verification Code"
      labelSize="lg"
      bsSize="lg"
      stacked
    />
  );
};

VerificationCodeInput.propTypes = {
  setValidCode: PropTypes.func
};

export default VerificationCodeInput;
