import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from '@appfolio/react-gears';

import auth from '../../lib/auth';

class AccountsPage extends React.Component {
  constructor(props) {
    super(props);

    this.selectAccount = this.selectAccount.bind(this);
  }

  componentDidMount() {
    const { history, location } = this.props;

    if (!location.state || !location.state.accounts) {
      history.replace({
        pathname: '/sign_in',
        search: location.search,
        state: location.state || {},
      });
    }
  }

  selectAccount(account) {
    const { history, location } = this.props;
    if (account.reverificationRequired) {
      return window.location.replace(`https://${account.vhost}`);
    }
    if (account.twoFactorSession) {
      if (account.phoneNumber || account.totpMfa || account.email2Fa) {
        return history.push({
          pathname: '/sign_in/mfa',
          search: location.search,
          state: { ...location.state, twoFactorState: account },
        });
      }
      return history.push({
        pathname: '/sign_in/mfa_enrollment',
        search: location.search,
        state: { ...location.state, twoFactorState: account },
      });
    }
    auth.setToken(account.accessToken);
    window.sessionStorage.setItem('passportToken', account.accessToken);
    if ((location.state?.from?.pathname ?? '/') === '/') {
      return auth.logInToVhost();
    }
    return history.replace(location.state.from);
  }

  render() {
    const state = this.props.location.state || {};
    const accounts = state.accounts || {};

    return (
      <div className="Page d-grid gap-1">
        <h1 className="text-lowercase mb-4">Choose an account</h1>
        {Object.keys(accounts).map((vhost) => {
            return {
              vhost,
              account: accounts[vhost],
              hasTimedOut: accounts[vhost].hasTimedOut
            };
          })
          .map(({ vhost, account, hasTimedOut }) => (
            <Button
              key={vhost}
              className="VHostButton p-4 text-start"
              onClick={() => this.selectAccount(account)}
              disabled={hasTimedOut}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
              >
                <p className="text-truncate m-0">
                  {vhost}
                </p>
                { hasTimedOut
                  &&
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  >
                    <Icon
                      name='exclamation-circle'
                      size='lg'
                      style={{
                        marginRight: '0.5em',
                        color: 'orange'
                      }}
                    />
                    <div>
                      Cannot connect to server
                    </div>
                  </div>}
              </div>
            </Button>
          ))
        }
      </div>
    );
  }
}

AccountsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
};

export default AccountsPage;
