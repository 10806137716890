import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appfolio/react-gears';

const SendCodeButton = ({ value, onClick }) => (
  <Button key="submit_btn" color="primary" onClick={onClick}>
    {value}
  </Button>
);

SendCodeButton.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

export default SendCodeButton;
