import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

import { Alert } from '@appfolio/react-gears';

export class FlashMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
    };

    this.transitionDuration = 300;
    this.defaultStyle = {
      transition: `opacity ${this.transitionDuration}ms ease-in-out`,
      opacity: 0,
    };

    this.transitionStyles = {
      entering: { opacity: 0 },
      entered: { opacity: 1 },
    };

    if (props.duration !== 0) {
      setTimeout(() => {
        this.setState({ active: false });
      }, props.duration);
    }
  }

  render() {
    const { color, message, children } = this.props;
    const { active } = this.state;
    const { transitionDuration, defaultStyle, transitionStyles } = this;
    if (!message && !children?.props) {
      return null;
    }
    return (
      <Transition in={active} timeout={transitionDuration}>
        {state => (
          <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <Alert color={color}>{message || children}</Alert>
          </div>
        )}
      </Transition>
    );
  }
}

export const SuccessMessage = props => <FlashMessage color="success" {...props} />;

export const ErrorMessage = props => <FlashMessage color="danger" {...props} />;

export const InfoMessage = props => <FlashMessage color="info" {...props} />;

FlashMessage.defaultProps = {
  color: '',
  duration: 0,
  message: '',
  children: undefined,
};

FlashMessage.propTypes = {
  color: PropTypes.string,
  duration: PropTypes.number,
  message: PropTypes.string,
  children: PropTypes.node,
};

ErrorMessage.defaultProps = {
  duration: 0,
  message: '',
  children: undefined,
};

ErrorMessage.propTypes = {
  duration: PropTypes.number,
  message: PropTypes.string,
  children: PropTypes.node,
};

SuccessMessage.defaultProps = {
  duration: 0,
  message: '',
  children: undefined,
};

SuccessMessage.propTypes = {
  duration: PropTypes.number,
  message: PropTypes.string,
  children: PropTypes.node,
};
