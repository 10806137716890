import React from 'react';
import PropTypes from 'prop-types';
import { BoundForm, BoundFormRow, Button, ButtonToolbar, FormChoice } from '@appfolio/react-gears';

const parseErrors = errors =>
  Object.assign({}, ...Object.keys(errors || {}).map((field) => {
    const key = field === 'application' ? 'application_id' : field;
    return { [key]: [...(errors[field] || [])].pop() };
  }));

const Form = ({ apps, errors, onCancel, onSubmit, readOnly, ...fields }) => {
  const appChoices = [
    <FormChoice key="placeholder">Select Application...</FormChoice>,
  ];

  if (apps && apps.length > 0) {
    const choices = apps.map(app => (
      <FormChoice
        key={app.client_id}
        value={app.client_id}
      >
        {app.name}
      </FormChoice>
    ));
    appChoices.push(...choices);
  }

  return (
    <BoundForm object={fields} onSubmit={onSubmit} errors={parseErrors(errors)}>
      {fields.uuid && (
        <BoundFormRow
          name="uuid"
          label="Client UUID"
          stacked
          readOnly
          className="js-uuid"
        />
      )}
      <BoundFormRow
        name="name"
        label="Client Name"
        readOnly={readOnly}
        required
        stacked
        className="js-name"
      />
      <BoundFormRow
        name="application_id"
        type="select"
        label="Application"
        readOnly={readOnly}
        required
        stacked
        className="js-application-id"
      >
        {appChoices}
      </BoundFormRow>
      <ButtonToolbar>
        <Button
          type="submit"
          color="primary"
          disabled={readOnly}
          className="js-save-btn"
        >
          Save
        </Button>
        <Button
          color="secondary"
          onClick={onCancel}
          className="js-cancel-btn"
        >
          Cancel
        </Button>
      </ButtonToolbar>
    </BoundForm>
  );
};

Form.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired,
  })),
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  name: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  uuid: PropTypes.string,
};

Form.defaultProps = {
  apps: [],
  errors: {},
  name: '',
  readOnly: false,
  uuid: '',
};

export default Form;
