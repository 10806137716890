import api from './api';

const callEndpoint = async (method, clientID = '', app) => {
  const { data } = await api.request(`/oauth/applications/${clientID}`, {
    method,
    body: app,
    authenticated: true,
  });

  return data;
};

const validateClientID = (clientID) => {
  if (!clientID) { throw new Error('Missing Client ID'); }
};

export default {
  async list() {
    const { applications } = await callEndpoint('GET');
    return applications;
  },
  async get(clientID) {
    validateClientID(clientID);
    return callEndpoint('GET', clientID);
  },
  async create(app) {
    return callEndpoint('POST', '', app);
  },
  async update(app) {
    validateClientID(app.client_id);
    return callEndpoint('PATCH', app.client_id, app);
  },
  async destroy(clientID) {
    validateClientID(clientID);
    return callEndpoint('DELETE', clientID);
  },
};
