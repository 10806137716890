import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardTitle, CardHeader, CardFooter, CardBody } from '@appfolio/react-gears';

import application from '../lib/application';
import rollbar from '../lib/rollbar';
import { UnauthorizedError } from '../lib/errors';

import ApplicationsList from './ApplicationsList';

class ApplicationsPage extends Component {
  state = {
    apps: null,
  };

  async componentDidMount() {
    try {
      const apps = await application.list();
      this.setState({ apps });
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        window.location.assign('/sign_out');
        return;
      }

      rollbar.error('Error fetching clients', error);
    }
  }

  render() {
    const { apps } = this.state;

    return apps ?
      <Card>
        <CardHeader>
          <CardTitle>Applications</CardTitle>
        </CardHeader>
        <CardBody>
          <ApplicationsList items={apps} />
        </CardBody>
        <CardFooter>
          <Link to="/applications/create">New Application</Link>
        </CardFooter>
      </Card> :
      <div className="spinner" />;
  }
}

export default ApplicationsPage;
