/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

import { ErrorMessage, SuccessMessage } from '../FlashMessage';

import { UnauthorizedError } from '../../lib/errors';
import twoFactor from '../../lib/twoFactor';
import rollbar from '../../lib/rollbar';
import * as TwoFactorMethod from '../../constants/TwoFactorMethod';
import RedirectToSignIn from './MFAPageComponents/RedirectToSignIn';
import LoadingSpinnerPage from '../LoadingSpinnerPage';
import SendCodeSection from './MFAPageComponents/SendCodeSection';
import EnterVerificationSection from './MFAPageComponents/EnterVerificationSection';

export default class MFAPage extends React.Component {
  constructor(props) {
    super(props);

    this.hasTwoFactorData = props.location.state && props.location.state.twoFactorState;
    const twoFactorMethod = this.#twoFactorMethodFromProps();

    this.state = {
      error: '',
      loading: true,
      twoFactorMethod,
      rememberMyDevice: false,
      displayVerification: twoFactorMethod === TwoFactorMethod.TOTP,
    };
  }

  #twoFactorMethodFromProps = () => {
    const { location } = this.props;
    if (location?.state?.twoFactorState?.totpMfa) {
      return TwoFactorMethod.TOTP;
    }
    if (location?.state?.twoFactorEnrollmentMethod) {
      return location.state.twoFactorEnrollmentMethod;
    }
    return this.hasTwoFactorData?.email2Fa
      ? TwoFactorMethod.EMAIL
      : TwoFactorMethod.SMS;
  }

  async componentDidMount() {
    const { location } = this.props;
    const promises = [];

    promises.push(this.#checkTwoFactorToken());
    if (location.state?.twoFactorEnrollmentMethod) {
      promises.push(this.#requestCode());
    }

    await Promise.all(promises);
  }

  #checkTwoFactorToken = async () => {
    try {
      const { history, location } = this.props;
      const { success } = await twoFactor.loginWithToken({ history, location });

      if (!success) {
        this.setState({ loading: false });
      }
    } catch (err) {
      rollbar.error('MFAPage: Error calling checkTwoFactorToken', err);
      this.setState({ error: err.message, loading: false });
    }
  }

  #requestCode = async () => {
    try {
      await twoFactor.request({
        location: this.props.location,
        twoFactorMethod: this.state.twoFactorMethod
      });

      this.setState({
        error: '',
        displayVerification: true,
      });
    } catch (err) {
      rollbar.error('MFAPage: Error calling requestTwoFactor', err);
      this.setState({
        error: err.message,
        displayVerification: false
      });
    }
  }

  render() {
    if (!this.hasTwoFactorData) {
      return <RedirectToSignIn location={this.props.location} />;
    }

    if (this.state.loading) {
      return <LoadingSpinnerPage />;
    }

    const { displayVerification, twoFactorMethod } = this.state;

    return (
      <div className="Page">
        <h1 className="mb-4">2-Step Verification</h1>
        <ErrorMessage message={this.state.error} />
        {
          displayVerification
            ? this.#enterVerificationDisplay()
            : <SendCodeSection
                twoFactorMethod={twoFactorMethod}
                onChange={e => this.setState({ twoFactorMethod: e.target.value })}
                onSubmit={this.#requestCode}
            />
        }
      </div>
    );
  }

  #enterVerificationDisplay = () => {
    const { twoFactorMethod } = this.state;
    const successMessage = this.#codeSentMessage();

    return (
      <>
        {!this.state.error && successMessage && <SuccessMessage message={successMessage} />}
        <EnterVerificationSection
          search={this.props.location.search}
          rememberMyDevice={this.state.rememberMyDevice}
          setRememberMyDevice={remember => this.setState({ rememberMyDevice: remember })}
          clearError={() => this.setState({ error: '' })}
          showResend={twoFactorMethod !== TwoFactorMethod.TOTP}
          onResend={() => this.setState({ displayVerification: false })}
          submitCode={this.#submitTwoFactor}
        />
      </>
    );
  }

  #codeSentMessage = () => {
    const { twoFactorState } = this.props.location.state;
    const { twoFactorMethod } = this.state;
    if (twoFactorMethod === TwoFactorMethod.EMAIL) {
      return `A verification code has been sent to ${twoFactorState.username}`;
    }
    if (twoFactorMethod === TwoFactorMethod.CALL || twoFactorMethod === TwoFactorMethod.SMS) {
      return `A verification code has been sent to a number ending in ${twoFactorState.phoneNumber?.slice(-4) ?? ''}`;
    }
    return '';
  }

  #submitTwoFactor = async (twoFactorCode, rememberMyDevice) => {
    try {
      const { history, location } = this.props;

      const { success } = await twoFactor.login({
        history,
        location,
        twoFactorCode,
        rememberMyDevice,
      });

      if (!success) {
        if (location.state?.twoFactorState?.email2Fa) {
          history.replace({
            pathname: '/sign_in/mfa_enrollment',
            search: location.search,
            state: location.state || {},
          });
        } else {
          history.replace(location.state.from);
        }
      }
    } catch (err) {
      if (!(err instanceof UnauthorizedError)) {
        rollbar.error('MFAPage: Error calling submitTwoFactor', err);
      }
      this.setState({ error: err.message });
    }
  }
}

MFAPage.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
};

MFAPage.defaultProps = {};
