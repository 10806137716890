import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import CenteredLayout from './CenteredLayout';
import Navigation from './Navigation';
import ApplicationsPage from './ApplicationsPage';
import AuthorizationPage from './AuthorizationPage';
import ApplicationPage from './ApplicationPage';
import NewApplicationPage from './NewApplicationPage';
import BasicClients from './BasicClients';
import LoginLayout from './Login';
import LogoutPage from './LogoutPage';
import NotFoundPage from './NotFoundPage';
import ProtectedRoute from './ProtectedRoute';

import '../style/base.css';
import '../style/components/Spinner.css';

const publicLayout = WrappedComponent => (
  props => (
    <CenteredLayout>
      <WrappedComponent {...props} />
    </CenteredLayout>
  )
);

const adminLayout = WrappedComponent => (
  props => (
    <>
      <Navigation />
      <CenteredLayout admin>
        <div className="pb-4">
          <WrappedComponent {...props} />
        </div>
      </CenteredLayout>
    </>
  )
);

const App = ({ Router }) => (
  <Router>
    <Switch>
      <Route path="/sign_in" component={LoginLayout} />
      <Route exact path="/sign_out" component={LogoutPage} />
      <Redirect exact from="/" to="/applications" />
      <ProtectedRoute exact path="/authorize" component={publicLayout(AuthorizationPage)} />
      <ProtectedRoute exact path="/applications" component={adminLayout(ApplicationsPage)} />
      <ProtectedRoute exact path="/applications/create" component={adminLayout(NewApplicationPage)} />
      <ProtectedRoute exact path="/applications/:id" component={adminLayout(ApplicationPage)} />
      <ProtectedRoute path="/basic/clients" component={adminLayout(BasicClients)} />
      <Route path="*" component={publicLayout(NotFoundPage)} />
    </Switch>
  </Router>
);

App.propTypes = {
  Router: PropTypes.elementType,
};

App.defaultProps = {
  Router: BrowserRouter,
};

export default App;
