import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, FormRow } from '@appfolio/react-gears';

import helpers from '../../../lib/helpers';

class RequestAccessLinkForm extends React.Component {
  state = {
    username: this.props.username || '',
    usernameError: '',
  };

  submit = (e) => {
    e.preventDefault();
    const { username } = this.state;

    const validator = new helpers.FormValidator(['username']);
    validator.validate({ username });

    if (validator.valid) {
      this.setState({ usernameError: '' });
      this.props.onSubmit({
        username,
      });
    } else {
      this.setState(validator.errors);
    }
  };

  render() {
    const { readOnly } = this.props;
    return (
      <Form noValidate onSubmit={this.submit}>
        <FormRow
          id="usernameInput"
          name="username"
          placeholder="john.smith@email.com"
          label="Email"
          labelSize="lg"
          size="lg"
          type="email"
          value={this.state.username}
          onChange={e => this.setState({ username: e.target.value })}
          stacked
          readOnly={readOnly}
          color={this.state.usernameError ? 'danger' : null}
          feedback={this.state.usernameError}
        />
        <Button
          size="lg"
          type="submit"
          color="primary"
          className="p-3 w-100 mt-4"
          disabled={readOnly}
        >
          Send Access Link
        </Button>
      </Form>
    );
  }
}

RequestAccessLinkForm.propTypes = {
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  username: PropTypes.string,
};

RequestAccessLinkForm.defaultProps = {
  readOnly: false,
  username: '',
};

export default RequestAccessLinkForm;
