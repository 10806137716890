import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { FormRow, FormChoice, FormLabelGroup, Button, ExpandableSection } from '@appfolio/react-gears';
import 'react-phone-input-2/lib/bootstrap.css';
import PhoneInput from 'react-phone-input-2';

import { ErrorMessage, InfoMessage } from '../FlashMessage';

import phoneNumberIsValid from '../../lib/phoneNumberHelper';
import * as TwoFactorMethod from '../../constants/TwoFactorMethod';
import idp from '../../lib/idp';

export default class MFAEnrollmentPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      number: '',
      countryCode: '',
      phoneNumberError: '',
      twoFactorMethod: TwoFactorMethod.SMS,
    };

    this.updateNumber = this.updateNumber.bind(this);
    this.updateTwoFactorMethod = this.updateTwoFactorMethod.bind(this);
    this.submitEnrollment = this.submitEnrollment.bind(this);
  }

  get identityProvider() {
    return idp.typeFromQuery(this.props.location.search);
  }

  twoFactorState = () => (
    this.props.location.state ? this.props.location.state.twoFactorState : null
  );

  updateNumber(e) {
    const number = e.target.value;
    this.setState({ number, countryCode: 'US' });
  }

  updateInternationalNumber = (number, countryData) => {
    const prefixedNumber = '+'.concat(number);
    this.setState({ number: prefixedNumber, countryCode: countryData.countryCode });
  }

  updateTwoFactorMethod(e) {
    const twoFactorMethod = e.target.value;
    this.setState({ twoFactorMethod });
  }

  submitEnrollment() {
    this.setState({ phoneNumberError: '' });

    const phoneNumberError = phoneNumberIsValid(this.state.number) ? null : 'Please enter a valid phone number';
    if (phoneNumberError) {
      this.setState({ phoneNumberError });
      return;
    }

    const { history, location } = this.props;
    const twoFactorDataWithPhone = {
      ...this.twoFactorState(),
      phoneNumber: this.state.number,
      email2Fa: false,
    };

    history.push({
      pathname: '/sign_in/mfa',
      search: location.search,
      state: {
        ...location.state,
        twoFactorState: twoFactorDataWithPhone,
        twoFactorEnrollmentMethod: this.state.twoFactorMethod,
      },
    });
  }

  render() {
    const { location } = this.props;
    const identityIDP = this.identityProvider === 'identity';
    let phoneNumberInput = (<FormRow
      feedback={this.state.phoneNumberError}
      name="phone_number"
      value={this.state.number}
      onChange={this.updateNumber}
      label="Phone Number"
      labelSize="lg"
      size="lg"
      stacked
    />);

    if (identityIDP) {
      phoneNumberInput = (
        <FormLabelGroup
          stacked
          feedback={this.state.phoneNumberError}
          label="Phone Number"
          labelSize="lg"
        >
          <PhoneInput
            enableSearch
            placeholder="Enter Phone Number"
            country="us"
            value={this.state.number}
            onChange={this.updateInternationalNumber}
          />
        </FormLabelGroup>
      );
    }

    if (!this.twoFactorState()) {
      return (
        <div className="Page">
          <Redirect
            to={{
              pathname: '/sign_in',
              search: location.search,
              state: location.state || {},
            }}
          />
        </div>
      );
    }

    return (
      <div className="Page">
        {
          this.twoFactorState().email2Fa &&
          <InfoMessage>
            <>
              To limit our customers exposure to fraud, we&apos;re no longer offering email-based 2FA.
              You must update your account with a phone number that receives texts.
            </>
          </InfoMessage>
        }
        <h1 className="mb-4">Set up 2-factor authentication</h1>
        <ErrorMessage message={this.state.error} />
        <p>
          Going forward, to keep your account safe, signing in to your account will periodically
          require the use of your password as well as a security code sent to your mobile device.&nbsp;
          <a href="https://property.cdn.appfolio.com/assets/property/Two-Factor_Authentication_PDF-84561f041090ca7fba67b02242412527c86ad7fbb1af4a8f2e172a566010ac2a.pdf">Learn more.</a>
        </p>
        <p>
          Please enter the phone number where you would like to receive your verification code.
        </p>

        <FormRow
          name="two_factor_method"
          required
          onChange={this.updateTwoFactorMethod}
          type="radio"
        >
          <FormChoice
            defaultChecked
            value={TwoFactorMethod.SMS}
          >
            Receive code via SMS
          </FormChoice>
          <FormChoice
            value={TwoFactorMethod.CALL}
          >
            Receive code via phone call
          </FormChoice>
        </FormRow>

        {phoneNumberInput}

        <Button
          color="primary"
          onClick={this.submitEnrollment}
        >
          Send verification code
        </Button>
        {
          this.twoFactorState().email2Fa &&
          <div className='mt-4'>
            <ExpandableSection
              title={<div><b>Why is two-factor authentication via email retiring?</b></div>}
            >
              At AppFolio, we constantly seek ways to limit our customers&apos; exposure to fraud
              and security risks. Protecting the security of your account is our topmost priority.
              Most email account services, such as Gmail, yahoo, outlook, etc, use passwords for
              login. Passwords are not secure as they get leaked in data breaches and can be guessed.
              If the password for your email service is compromised and known by a bad actor, then it
              puts your AppFolio account at the risk as well.
            </ExpandableSection>
            <ExpandableSection
              className='mt-4'
              title={<div><b>What if I&apos;m traveling internationally?</b></div>}
            >
              <>
                <p>You have several options:</p>
                <ul>
                  <li>Enable international roaming to receive SMS and phone calls outside of the United States.</li>
                  <li>Use VoIP services like Google Voice that just require a wi-fi/internet connection. You would receive verification SMS and phone calls via Google Voice iOS/Android App or voice.google.com</li>
                  <li>You can add an international phone number to your account temporarily while traveling.</li>
                </ul>
                <p>
                  If you are repeatedly unable to receive codes, please contact support at&nbsp;
                  <a href="mailto:support@appfolio.com">support@appfolio.com</a> or&nbsp;
                  <a href="tel:866.648.1536">866.648.1536</a>.
                </p>
              </>
            </ExpandableSection>

          </div>
        }
      </div>
    );
  }
}

MFAEnrollmentPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
  }).isRequired,
};

MFAEnrollmentPage.defaultProps = {};
