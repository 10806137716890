import React from 'react';
import PropTypes from 'prop-types';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  UncontrolledDropdown,
} from '@appfolio/react-gears';

const Controls = ({ onClickDelete, onClickEdit }) => (
  <UncontrolledDropdown>
    <DropdownToggle
      aria-haspopup
      className="py-0 px-2"
      color="link"
      role="button"
      title="More options"
    >
      <Icon name="ellipsis-h" size="lg" />
    </DropdownToggle>
    <DropdownMenu flip end tag="div">
      <DropdownItem
        className="js-edit"
        onClick={onClickEdit}
        tag="button"
      >
        Edit
      </DropdownItem>
      <DropdownItem
        className="text-danger js-delete"
        onClick={onClickDelete}
        tag="button"
      >
        Delete
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

Controls.propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
};

export default Controls;
