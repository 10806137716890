import React from 'react';
import PropTypes from 'prop-types';

import { FormRow } from '@appfolio/react-gears';

const PasswordFormGroup = props => (
  <div>
    <FormRow
      stacked
      id="passwordInput"
      name="password"
      label="Password"
      labelSize="lg"
      size="lg"
      type="password"
      color={props.passwordError ? 'danger' : null}
      value={props.password}
      onChange={props.passwordOnChange}
      readOnly={props.readOnly}
    />
    <FormRow
      stacked
      id="passwordConfirmationInput"
      name="passwordConfirmation"
      label="Password Confirmation"
      labelSize="lg"
      size="lg"
      type="password"
      color={props.passwordError ? 'danger' : null}
      feedback={props.passwordError}
      value={props.passwordConfirmation}
      onChange={props.passwordConfirmationOnChange}
      readOnly={props.readOnly}
    />
  </div>
);

PasswordFormGroup.propTypes = {
  passwordOnChange: PropTypes.func.isRequired,
  passwordConfirmationOnChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  passwordError: PropTypes.string,
  password: PropTypes.string,
  passwordConfirmation: PropTypes.string,
};

PasswordFormGroup.defaultProps = {
  password: '',
  passwordConfirmation: '',
  passwordError: '',
};

export default PasswordFormGroup;
