import React from 'react';
import PropTypes from 'prop-types';

import helpers from '../lib/helpers';
import BrandingLogos from '../constants/BrandingLogos';

const Logo = ({ location, className }) => {
  const { idpType, branding } = helpers.parseQueryString(location.search);
  if (idpType && idpType === 'vendor') {
    return (
      <img
        className={className}
        src="https://public.cdn.appfolio.com/public/images/af-white.svg"
        alt="AppFolio Logo"
      />
    );
  }

  const { logoUrl, logoAlt } = BrandingLogos[branding || 'default'];
  return (
    <img
      className={className}
      src={logoUrl}
      alt={logoAlt}
    />
  );
};


Logo.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

Logo.defaultProps = {
  className: '',
};

export default Logo;
