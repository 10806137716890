import helpers from './helpers';

export default {
  typeFromQuery: query => helpers.parseQueryString(query).idpType,
  vhostlessFromQuery: query => helpers.parseQueryString(query).vhostless === 'true',
  authCodeClientId: (idpType) => {
    let clientId;
    switch (idpType) {
      case 'property':
        clientId = process.env.AUTH_CODE_PROPERTY_CLIENT_ID;
        break;
      case 'tportal':
        clientId = process.env.AUTH_CODE_TPORTAL_CLIENT_ID;
        break;
      default:
        throw new Error(`Unsupported idpType: ${idpType}`);
    }

    return clientId;
  },
};
