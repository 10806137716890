import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Alert, BlockPanel, Icon, UncontrolledTable } from '@appfolio/react-gears';

import basicClients from '../../lib/basicClients';
import rollbar from '../../lib/rollbar';
import { UnauthorizedError } from '../../lib/errors';

class List extends Component {
  constructor(props) {
    super(props);

    const { history } = this.props;
    const { state } = history.location;

    let flash = null;
    if (state && state.deleted) {
      flash = `Deleted client ${state.deleted.name}.`;
      history.replace(history.location.pathname);
    }

    this.state = {
      clients: null,
      flash,
    };
  }

  async componentDidMount() {
    const { history } = this.props;

    try {
      const clients = await basicClients.list();
      const keyed = clients.map((c) => {
        return {
          ...c,
          nameLower: c.name.toLowerCase(),
          key: c.uuid,
        };
      });
      this.setState({ clients: keyed });
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        history.push('/sign_out');
        return;
      }

      rollbar.error('Error fetching clients', error);
    }
  }

  render() {
    const { clients, flash } = this.state;

    if (!clients) {
      return <div className="spinner" />;
    }

    return (
      <>
        {flash && (
          <Alert
            color="success"
            dismissible
            icon
          >
            {flash}
          </Alert>
        )}
        <BlockPanel
          controls={[
            <Link
              className="btn btn-secondary btn-sm"
              to="/basic/clients/new"
            >
              <Icon fixedWidth name="plus-circle" />
              Add Client
            </Link>,
          ]}
          open
          title="External Clients"
        >
          {clients.length > 0 ? (
            <UncontrolledTable
              columns={[
                {
                  cell: client => (
                    <Link
                      key={client.uuid}
                      className="p-0 py-1 js-client"
                      to={`/basic/clients/${client.uuid}`}
                    >
                      {client.name}
                    </Link>
                  ),
                  className: 'js-name',
                  header: 'Name',
                  key: 'nameLower',
                },
              ]}
              hover={false}
              rows={clients}
              paginated
              pageSize={20}
              sort={{ column: 'nameLower', ascending: true }}
            />
          ) : (
            <span className="js-no-clients">There aren&#8217;t any clients</span>
          )}
        </BlockPanel>
      </>
    );
  }
}

List.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        deleted: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    }).isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default List;
