import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@appfolio/react-gears';

const DeleteConfirmationModal = ({ disabled, name, onClose, onDelete }) => (
  <Modal isOpen backdrop>
    <ModalHeader toggle={onClose}>
      {`Delete "${name}"?`}
    </ModalHeader>
    <ModalBody>
      <p>
        {`Are you sure you want to delete the client "${name}"?`}
      </p>
      <p>This cannot be undone.</p>
    </ModalBody>
    <ModalFooter>
      <Button
        color="danger"
        onClick={onDelete}
        disabled={disabled}
        className="js-confirm-btn"
      >
        Yes, delete it
      </Button>
      <Button
        color="secondary"
        onClick={onClose}
        disabled={disabled}
        className="js-cancel-btn"
      >
        No, keep it
      </Button>
    </ModalFooter>
  </Modal>
);

DeleteConfirmationModal.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

DeleteConfirmationModal.defaultProps = {
  disabled: false,
};

export default DeleteConfirmationModal;
