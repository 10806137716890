import React from 'react';
import PropTypes from 'prop-types';
import * as TwoFactorMethod from '../../../constants/TwoFactorMethod';
import EmailCodeSection from './EmailCodeSection';
import PhoneCodeSection from './PhoneCodeSection';

const SendCodeSection = ({ twoFactorMethod, onChange, onSubmit }) => (
  twoFactorMethod === TwoFactorMethod.EMAIL
    ? <EmailCodeSection onSubmit={onSubmit} />
    : <PhoneCodeSection
        value={twoFactorMethod}
        onChange={onChange}
        onSubmit={onSubmit}
    />
);

SendCodeSection.propTypes = {
  twoFactorMethod: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export default SendCodeSection;
