import React from 'react';
import qs from 'qs';

import authorization from '../lib/authorization';
import rollbar from '../lib/rollbar';
import AuthorizationForm from './AuthorizationForm';
import auth from '../lib/auth';
import { UnauthorizedError } from '../lib/errors';

class AuthorizationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appName: '',
      error: '',
    };

    const sessionStorageAuthRedirect = window.sessionStorage.getItem('authRedirect');
    const search = JSON.parse(sessionStorageAuthRedirect).search;
    this.params = qs.parse(search, { ignoreQueryPrefix: true });
  }

  async componentDidMount() {
    try {
      const { name: appName, redirectURI } = await authorization.check(this.params);
      if (redirectURI) {
        auth.logOut();
        window.location.replace(redirectURI);
      } else {
        this.setState({ appName });
      }
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        window.location.assign('/sign_out');
        return;
      }
      rollbar.error('AuthorizationPage: Error calling componentDidMount', error);
      this.setState({ error: "Sorry, the page couldn't be loaded." });
    }
  }

  async handleAuthorize(action) {
    const { redirectURI } = await action(this.params);
    auth.logOut();
    window.location.replace(redirectURI);
  }

  render() {
    const { appName, error } = this.state;

    if (error) {
      return <span>{error}</span>;
    }

    return appName ? (
      <AuthorizationForm
        appName={appName}
        approve={() => this.handleAuthorize(authorization.approve)}
        deny={() => this.handleAuthorize(authorization.deny)}
      />
    ) : (
      <div className="spinner" />
    );
  }
}

export default AuthorizationPage;
