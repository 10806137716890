import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@appfolio/react-gears';

import '../../../style/components/RequestAccessLink.css';

class RequestAccessLinkBtn extends React.Component {
  onSendAccessLink = () => {
    const { redirectTo, match } = this.props;
    redirectTo({
      pathname: `${match.url}/request_access_link`,
      state: {
        from: match.url,
        username: this.props.username,
      },
    });
  }

  render() {
    return (
      <div>
        <Button
          type="submit"
          color="primary"
          className="p-3 w-100 mb-5"
          size="lg"
          onClick={() => this.onSendAccessLink()}
        >
          Send an Access Link to Your Email
        </Button>
        <hr />
        <div className="RequestAccessLink-Or">or</div>
      </div>
    );
  }
}

RequestAccessLinkBtn.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  redirectTo: PropTypes.func.isRequired,
  username: PropTypes.string,
};

RequestAccessLinkBtn.defaultProps = {
  username: '',
};

export default RequestAccessLinkBtn;
