import React from 'react';
import PropTypes from 'prop-types';

import { Col, Container, Row } from '@appfolio/react-gears';

const CenteredLayout = ({ admin, children }) => (
  <Container>
    <Row
      className={`justify-content-center ${admin ? '' : 'align-items-center'}`}
      style={{ minHeight: admin ? 'calc(100vh - 60px)' : '100vh' }}
    >
      <Col md="6">
        {children}
      </Col>
    </Row>
  </Container>
);

CenteredLayout.defaultProps = {
  admin: false,
  children: '',
};

CenteredLayout.propTypes = {
  admin: PropTypes.bool,
  children: PropTypes.node,
};

export default CenteredLayout;
