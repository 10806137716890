import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const RedirectToSignIn = ({ location }) => (
  <div className="Page">
    <Redirect
      to={{
        pathname: '/sign_in',
        search: location.search,
        state: location.state || {},
      }}
    />
  </div>
);

RedirectToSignIn.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object
  })
};

export default RedirectToSignIn;
