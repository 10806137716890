import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Datapair, Icon, Input } from '@appfolio/react-gears';

import basicCredentials from '../../../lib/basicCredentials';

import ConfirmAction from './ConfirmAction';

const classNames = ({ label, monospace }) => (
  `p-0 ${monospace ? 'font-monospace' : ''} js-${label.replace(/\s/g, '-').toLowerCase()}`
);

const Pair = ({ label, monospace, value }) => (
  <Datapair label={label} stacked>
    <Input
      className={classNames({ label, monospace })}
      plaintext
      tag="div"
    >
      {value}
    </Input>
  </Datapair>
);

Pair.propTypes = {
  label: PropTypes.string.isRequired,
  monospace: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

Pair.defaultProps = {
  monospace: true,
};

class Show extends Component {
  state = {
    confirming: false,
    disabled: false,
    secret: this.props.credential.secret,
  };

  showSecret = async () => {
    const { credential } = this.props;
    const { secret } = await basicCredentials.get(credential.uid);
    this.setState({ secret });
  };

  destroy = async () => {
    const { credential, onDeleted } = this.props;
    this.setState({ disabled: true });
    await basicCredentials.destroy(credential.uid);
    onDeleted();
  };

  rotate = async () => {
    const { credential, onRotated } = this.props;
    this.setState({ disabled: true });
    const newCredentials = await basicCredentials.rotate(credential.uid);
    this.setState({ disabled: false, secret: newCredentials.secret });
    onRotated(newCredentials);
  };

  confirmAction = action => () => this.setState({ confirming: action });

  cancelAction = () => this.setState({ confirming: false });

  performAction = async () => {
    const { confirming } = this.state;
    this.setState({ confirming: false });
    if (confirming === 'rotate') {
      await this.rotate();
    } else if (confirming === 'delete') {
      await this.destroy();
    }
  };

  render() {
    const { credential } = this.props;
    const { confirming, disabled, secret } = this.state;

    return (
      <>
        <Pair label="ID" value={credential.uid} />
        <Pair
          label="Secret"
          monospace={!!secret}
          value={secret || (
            <>
              <span className="font-monospace">{'*'.repeat(32)}</span>
              <Button
                color="link"
                onClick={this.showSecret}
                size="sm"
                disabled={disabled}
              >
                Reveal
              </Button>
            </>
          )}
        />
        <Pair label="Vhost Guid" value={credential.vhost_guid} />
        {confirming && (
          <ConfirmAction
            action={confirming}
            disabled={disabled}
            onCancel={this.cancelAction}
            onConfirm={this.performAction}
          />
        )}
        {!confirming && (
          <ButtonToolbar className="mt-4">
            <Button
              className="js-rotate-btn"
              disabled={disabled}
              onClick={this.confirmAction('rotate')}
            >
              <Icon fixedWidth name="refresh" /> Rotate
            </Button>
            <Button
              className="js-delete-btn"
              disabled={disabled}
              onClick={this.confirmAction('delete')}
            >
              <Icon fixedWidth name="trash" /> Delete
            </Button>
          </ButtonToolbar>
        )}
      </>
    );
  }
}

Show.propTypes = {
  credential: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    secret: PropTypes.string,
    vhost_guid: PropTypes.string.isRequired,
  }).isRequired,
  onDeleted: PropTypes.func.isRequired,
  onRotated: PropTypes.func.isRequired,
};

export default Show;
