import api from './api';

const callEndpoint = async (method, clientId, app) => {
  const path = clientId ? `/basic_clients/${clientId}` : '/basic_clients';
  const { data } = await api.request(path, {
    method,
    body: app,
    authenticated: true,
  });

  return data ? data.data : null;
};

const validateClientId = (clientId) => {
  if (!clientId) { throw new Error('Missing Client ID'); }
};

export default {
  async list() {
    const clients = await callEndpoint('GET');
    return clients || [];
  },
  async get(clientId) {
    validateClientId(clientId);
    return callEndpoint('GET', clientId);
  },
  async create(app) {
    return callEndpoint('POST', null, app);
  },
  async update(app) {
    validateClientId(app.uuid);
    return callEndpoint('PATCH', app.uuid, app);
  },
  async destroy(clientId) {
    validateClientId(clientId);
    return callEndpoint('DELETE', clientId);
  },
};
