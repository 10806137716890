import React from 'react';
import PropTypes from 'prop-types';

import { Button, Card, CardBody, CardHeader, CardTitle } from '@appfolio/react-gears';

const AuthorizationForm = ({ appName, approve, deny }) => (
  <Card className="text-center">
    <CardHeader>
      <CardTitle className="mb-4">Authorization Required</CardTitle>
    </CardHeader>
    <CardBody>
      <p>
        <b>{appName}</b> is requesting temporary access to use your account. Do you want to allow
        access?
      </p>
      <Button
        block
        color="success"
        onClick={approve}
        className="p-3 mb-4"
        style={{ whiteSpace: 'normal' }}
      >
        <b>Yes, temporarily authorize {appName}</b>
      </Button>
      <div className="text-start">
        <Button color="link" onClick={deny} className="p-0">
          No thanks, go back.
        </Button>
      </div>
    </CardBody>
  </Card>
);

AuthorizationForm.propTypes = {
  appName: PropTypes.string.isRequired,
  approve: PropTypes.func.isRequired,
  deny: PropTypes.func.isRequired,
};

export default AuthorizationForm;
