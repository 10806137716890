import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BoundForm,
  BoundFormRow,
  Button,
  ButtonToolbar,
} from '@appfolio/react-gears';
import basicCredentials from '../../../lib/basicCredentials';

const parseErrors = errors =>
  Object.assign({}, ...Object.keys(errors || {}).map((field) => {
    return { [field]: [...(errors[field] || [])].pop() };
  }));

class Form extends Component {
  state = {
    errors: {},
    saving: false,
  };

  save = async (e, form) => {
    const { clientId, onSaved } = this.props;
    this.setState({ saving: true });
    try {
      const credentials = await basicCredentials.create(clientId, form);
      onSaved(credentials);
    } catch ({ errors }) {
      this.setState({ saving: false, errors: errors || {} });
    }
  };

  render() {
    const { onCancel } = this.props;
    const { errors, saving } = this.state;
    return (
      <BoundForm object={{}} onSubmit={this.save} errors={parseErrors(errors)}>
        <BoundFormRow
          name="vhost_guid"
          label="Vhost Guid"
          readOnly={saving}
          required
          stacked
          className="js-vhost-guid"
        />
        <ButtonToolbar>
          <Button
            type="submit"
            color="primary"
            disabled={saving}
            className="js-save-btn"
          >
            Save
          </Button>
          <Button
            color="secondary"
            disabled={saving}
            onClick={onCancel}
            className="js-cancel-btn"
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </BoundForm>
    );
  }
}

Form.propTypes = {
  clientId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default Form;
