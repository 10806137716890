import React from 'react';
import { Redirect } from 'react-router-dom';

import auth from '../lib/auth';

const LogoutPage = () => {
  auth.logOut();
  return <Redirect to="/sign_in" />;
};

export default LogoutPage;
