import api from './api';

export default {
  async signup(credentials) {
    const {
      username,
      password,
      passwordConfirmation,
      guid,
      vendorId,
      companyName,
      companyEmail,
      magicLinkToken,
    } = credentials;

    const body = {
      magic_link_token: magicLinkToken,
      user: {
        email: username,
        password,
        password_confirmation: passwordConfirmation,
      },
      company: {
        email: companyEmail,
      },
      customer: {
        vhost_guid: guid,
        apm_vendor_id: vendorId,
        company_name: companyName,
      },
    };

    await api.request('/signup', {
      method: 'POST',
      vendorRequest: true,
      body,
    });
  },
  async redirectToVendor(newWindow = window) {
    const response = await api.request('/maintenance/api/vendor_login', {
      method: 'POST',
      vendorRequest: true,
      skipHandler: true,
      body: {},
    });
    const responseBody = await response.json();
    const { login_url: loginUrl } = responseBody;
    newWindow.location.replace(loginUrl);
  },
};
