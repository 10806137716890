import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@appfolio/react-gears';

const ResendButton = ({ onClick }) => (
  <Button
    key="resend_btn"
    size="lg"
    color="link"
    className="p-3 mt-3 mb-3 mx-3"
    onClick={onClick}
  >
    Send a new code
  </Button>
);

ResendButton.propTypes = {
  onClick: PropTypes.func
};

export default ResendButton;
