import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from '@appfolio/react-gears';

import Form from './Form';
import Show from './Show';

class CredentialModal extends Component {
  state = {
    credential: this.props.credential,
    rotated: false,
    saved: false,
  };

  onDeleted = () => {
    const { onClose } = this.props;
    const { credential } = this.state;
    onClose({ deleted: credential });
  };

  onRotated = credential => this.setState({ credential, rotated: true });

  onSaved = credential => this.setState({ credential, saved: true });

  closeModal = () => {
    const { onClose } = this.props;
    const { credential, rotated, saved } = this.state;

    const callbackVal = {};
    if (rotated) {
      callbackVal.rotated = credential;
    } else if (saved) {
      callbackVal.saved = credential;
    }
    onClose(callbackVal);
  };

  render() {
    const { client } = this.props;
    const { credential } = this.state;

    return (
      <Modal isOpen backdrop keyboard toggle={this.closeModal}>
        <ModalHeader toggle={this.closeModal}>
          {credential ? 'Credentials' : 'New Credentials'}
        </ModalHeader>
        <ModalBody>
          {credential && (
            <Show
              credential={credential}
              onDeleted={this.onDeleted}
              onRotated={this.onRotated}
            />
          )}
          {!credential && (
            <Form
              clientId={client.uuid}
              onCancel={this.closeModal}
              onSaved={this.onSaved}
            />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

CredentialModal.propTypes = {
  client: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  credential: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    secret: PropTypes.string,
    vhost_guid: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
};

CredentialModal.defaultProps = {
  credential: null,
};

export default CredentialModal;
