import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form, FormRow } from '@appfolio/react-gears';

import VHostFormRow from './VHostFormRow';
import helpers from '../../lib/helpers';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: props.email,
      password: '',
      vhost: '',
      usernameError: '',
    };

    this.submit = this.submit.bind(this);
    this.storeUsername = this.storeUsername.bind(this);
  }

  componentDidMount() {
    this.props.saveUsername(this.state.username);
  }

  submit(e) {
    e.preventDefault();
    const { username, password, vhost } = this.state;
    const { vhostless, idpType } = this.props;
    let vendorParams = {};
    if (idpType === 'vendor') {
      vendorParams = helpers.parseQueryString(window.location.search);
    }

    const validator = new helpers.FormValidator(['username']);
    validator.validate({ username });

    // non email usernames should be fine with internal idp since login_app enforces ownership
    // and uniqueness with a centralized db. Other idps support multiple identities accross
    // vhosts and may have issues with the same alias owned by different people.
    if (username === 'admin' || idpType === 'internal' || validator.valid) {
      this.setState({ usernameError: '' });
      this.props.onSubmit({
        username: username.toLowerCase(),
        password,
        vhost,
        vhostless,
        idpType,
        ...vendorParams,
      });
    } else {
      this.setState(validator.errors);
    }
  }

  storeUsername(e) {
    const user = e.target.value;
    this.setState({
      username: user,
    });
    this.props.saveUsername(user);
  }

  render() {
    const { readOnly, vhostless, idpType, failedAttempts } = this.props;
    return (
      <Form noValidate onSubmit={this.submit}>
        {(!vhostless || failedAttempts > 2) &&
          idpType && (!['internal', 'vendor', 'identity'].includes(idpType)) && (
          <VHostFormRow
            id="vhostInput"
            idpType={idpType}
            value={this.state.vhost}
            onChange={e => this.setState({ vhost: e.target.value })}
            readOnly={readOnly}
          />
        )}
        <FormRow
          id="usernameInput"
          name="username"
          placeholder="john.smith@email.com"
          label="Email"
          labelSize="lg"
          size="lg"
          type="email"
          color={this.state.usernameError ? 'danger' : null}
          feedback={this.state.usernameError}
          value={this.state.username}
          onChange={this.storeUsername}
          stacked
          readOnly={readOnly}
          autoComplete="off"
        />
        <FormRow
          stacked
          id="passwordInput"
          name="password"
          label="Password"
          labelSize="lg"
          size="lg"
          type="password"
          value={this.state.password}
          onChange={e => this.setState({ password: e.target.value })}
          readOnly={readOnly}
        />
        <Button
          size="lg"
          type="submit"
          color="primary"
          disabled={readOnly}
          className="p-3 w-100 mt-3 mb-3"
        >
          Sign In
        </Button>
      </Form>
    );
  }
}

LoginForm.propTypes = {
  idpType: PropTypes.oneOf(['', 'tportal', 'property', 'oportal', 'vendor', 'internal', 'identity']),
  vhostless: PropTypes.bool,
  email: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  failedAttempts: PropTypes.number,
  saveUsername: PropTypes.func,
};

LoginForm.defaultProps = {
  idpType: '',
  vhostless: false,
  email: '',
  readOnly: false,
  failedAttempts: 0,
  saveUsername: () => {},
};

export default LoginForm;
