import React from 'react';
import PropTypes from 'prop-types';

import ResetPasswordForm from './ResetPasswordForm';
import { ErrorMessage } from '../FlashMessage';

import auth from '../../lib/auth';
import helpers from '../../lib/helpers';
import vendor from '../../lib/vendor';

class ResetPasswordPage extends React.Component {
  state = {
    error: '',
    loading: false,
  };

  queryParams = helpers.parseQueryString(this.props.location.search);

  validationsToRun = [
    ...(this.queryParams.idpType === 'vendor' ? [] : ['passwordHasNumOrSym', 'passwordHasLetter']),
    'password',
    'passwordConfirmation',
  ];
  validator = new helpers.FormValidator(this.validationsToRun);

  resetPassword = async (credentials) => {
    this.setState({ loading: true });
    const { idpType, resetPasswordToken } = this.queryParams;
    const vendorOrIdentity = idpType === 'vendor' ? { vendorRequest: true } : { identityRequest: true };

    try {
      const {
        data: { email },
      } = await auth.submitResetPassword({
        ...credentials, resetPasswordToken, options: vendorOrIdentity,
      });
      if (idpType === 'identity') {
        window.location.replace(process.env.PARTNER_PORTAL_FRONTEND_BASE);
      } else {
        const { password } = credentials;
        await auth.logIn({ username: email, password, vhost: '', idpType: 'vendor' });
        await vendor.redirectToVendor();
      }
    } catch (err) {
      this.setState({ error: err.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;

    return (
      <div className="Page">
        <h1 className="mb-4">Reset password</h1>
        <ErrorMessage message={this.state.error} />
        <ResetPasswordForm
          validator={this.validator}
          onSubmit={this.resetPassword}
          readOnly={loading}
        />
      </div>
    );
  }
}

ResetPasswordPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.shape({
      from: PropTypes.object,
    }),
  }).isRequired,
};

export default ResetPasswordPage;
