import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import auth from '../../lib/auth';
import helpers from '../../lib/helpers';
import LoginPage from './LoginPage';
import Logo from '../Logo';
import MFAPage from './MFAPage';
import MFAEnrollmentPage from './MFAEnrollmentPage';
import AccountsPage from './AccountsPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import ResetPasswordPage from './ResetPasswordPage';
import RequestAccessLinkPage from './AccessLink/RequestAccessLinkPage';

import '../../style/components/Page.css';

const getAuthRedirect = (idpType, vhost) => {
  const sessionStorageAuthRedirect = window.sessionStorage.getItem('authRedirect');
  if (!sessionStorageAuthRedirect && idpType === 'vendor') {
    const redirectUri = (vhost && process.env.DEPLOYMENT_ENV === 'qa') ? `https://${vhost}.qa.appf.io` : process.env.VENDOR_API_BASE;
    return {
      pathname: '/authorize',
      search: `?client_id=vendor-portal&grant_type=code&idp_type=vendor&redirect_uri=${encodeURIComponent(redirectUri)}/oauth_callback`,
    };
  }
  return sessionStorageAuthRedirect ? JSON.parse(sessionStorageAuthRedirect) : '/';
};

const LoginLayout = ({ location, match }) => {
  const { idpType, vhost } = helpers.parseQueryString(location.search);
  if (auth.isLoggedIn()) {
    return <Redirect to={getAuthRedirect(idpType, vhost)} />;
  }

  return (
    <div className="container-fluid">
      <div className="row vh-md-100">
        <div className="col-md-5 col-sm-12 bg-primary d-flex justify-content-center align-items-center py-4">
          <Logo location={location} className="w-50 py-4" />
        </div>
        <div className="col-md-7 col-sm-12 pt-4 px-4">
          <TransitionGroup className="PageContainer d-flex flex-nowrap m-sm-auto h-100 align-items-sm-center">
            <CSSTransition key={location.key} classNames="Page" timeout={405}>
              <Switch location={location}>
                <Route exact path={match.url} component={LoginPage} />
                <Route path={`${match.url}/request_access_link`} component={RequestAccessLinkPage} />
                <Route path={`${match.url}/forgot_password`} component={ForgotPasswordPage} />
                <Route path={`${match.url}/reset_password`} component={ResetPasswordPage} />
                <Route path={`${match.url}/mfa`} component={MFAPage} />
                <Route path={`${match.url}/mfa_enrollment`} component={MFAEnrollmentPage} />
                <Route path={`${match.url}/accounts`} component={AccountsPage} />
                <Redirect to={{ pathname: match.url, search: location.search }} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
};

LoginLayout.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default LoginLayout;
