import React from 'react';
import PropTypes from 'prop-types';

import { Button, Form } from '@appfolio/react-gears';

import PasswordFormGroup from '../Signup/PasswordFormGroup';

class ResetPasswordForm extends React.Component {
  state = {
    password: '',
    passwordConfirmation: '',
    passwordError: '',
  };

  submit = (e) => {
    e.preventDefault();
    const { validator } = this.props;
    const { password, passwordConfirmation } = this.state;

    validator.validate({ password, passwordConfirmation });

    if (validator.valid) {
      this.setState({ passwordError: '' });
      this.props.onSubmit({
        password,
        passwordConfirmation,
      });
    } else {
      this.setState(validator.errors);
    }
  };

  render() {
    const { readOnly } = this.props;
    const { password, passwordConfirmation, passwordError } = this.state;

    return (
      <Form noValidate onSubmit={this.submit}>
        <PasswordFormGroup
          password={password}
          passwordConfirmation={passwordConfirmation}
          passwordError={passwordError}
          passwordOnChange={e => this.setState({ password: e.target.value })}
          passwordConfirmationOnChange={
            e => this.setState({ passwordConfirmation: e.target.value })
          }
          readOnly={readOnly}
        />
        <Button
          type="submit"
          color="primary"
          disabled={readOnly}
          className="p-3 w-100 mt-4"
        >
          Enter
        </Button>
      </Form>
    );
  }
}

ResetPasswordForm.propTypes = {
  readOnly: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  validator: PropTypes.shape({ validate: PropTypes.func }).isRequired,
};

ResetPasswordForm.defaultProps = {
  readOnly: false,
};

export default ResetPasswordForm;
