import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  BlockPanel,
  Button,
  Icon,
  Spinner,
  UncontrolledTable,
} from '@appfolio/react-gears';

import basicCredentials from '../../../lib/basicCredentials';

import CredentialModal from './CredentialModal';

class List extends Component {
  state = {
    credential: null,
    credentials: null,
    modalOpen: false,
  };

  async componentDidMount() {
    const { client } = this.props;

    const credentials = await basicCredentials.list(client.uuid);
    const keyed = credentials?.map((c) => {
      return { ...c, key: c.vhost_guid };
    });
    this.setState({ credentials: keyed });
  }

  onOpenModal = () => this.setState({ modalOpen: true });

  onCloseModal = ({ deleted, rotated, saved }) => {
    const newState = { credential: null, modalOpen: false };
    const credentials = this.state.credentials || [];

    if (saved) {
      // eslint-disable-next-line camelcase
      const { uid, vhost_guid } = saved;
      const cred = { uid, vhost_guid, key: vhost_guid };
      credentials.push(cred);
      newState.credentials = credentials;
    } else if (rotated) {
      const idx = credentials.findIndex(cred => cred.vhost_guid === rotated.vhost_guid);
      credentials[idx].uid = rotated.uid;
      newState.credentials = credentials;
    } else if (deleted) {
      const idx = credentials.findIndex(cred => cred.vhost_guid === deleted.vhost_guid);
      credentials.splice(idx, 1);
      newState.credentials = credentials;
    }
    this.setState(newState);
  };

  vhostCell = credential => (
    <Button
      key={credential.vhost_guid}
      className="font-monospace p-0 py-1"
      color="link"
      onClick={() => this.setState({ credential, modalOpen: true })}
    >
      {credential.vhost_guid}
    </Button>
  );

  render() {
    const { client } = this.props;
    const { credential, credentials, modalOpen } = this.state;

    return (
      <>
        <BlockPanel
          controls={[
            <Button key="add" size="sm" onClick={this.onOpenModal}>
              <Icon fixedWidth name="plus-circle" />
              Add Credentials
            </Button>,
          ]}
          open
          title="Credentials"
        >
          {!credentials && (
            <div className="text-center p-2">
              <Spinner size="2em" />
            </div>
          )}
          {credentials && credentials.length > 0 && (
            <UncontrolledTable
              columns={[
                {
                  cell: this.vhostCell,
                  className: 'js-vhost-guid',
                  header: 'Vhost Guid',
                  key: 'vhost_guid',
                },
              ]}
              hover={false}
              rows={credentials}
              paginated
              pageSize={20}
              sort={{ column: 'vhost_guid', ascending: true }}
            />
          )}
          {credentials && credentials.length === 0 && (
            <span className="js-no-creds">There aren&#8217;t any credentials for {client.name}.</span>
          )}
        </BlockPanel>
        {modalOpen && (
          <CredentialModal
            client={client}
            credential={credential}
            onClose={this.onCloseModal}
          />
        )}
      </>
    );
  }
}

List.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
};

export default List;
