import auth from './auth';
import idp from './idp';

export default {
  async login({ history, location, twoFactorCode, rememberMyDevice }) {
    if (rememberMyDevice !== undefined) {
      rememberMyDevice = rememberMyDevice ? '1' : '0';
    }

    await auth.logInWithTwoFactor({
      idpType: idp.typeFromQuery(location.search),
      twoFactorCode,
      rememberMyDevice,
      ...(location.state?.twoFactorState ?? {}),
    });

    const success = auth.isLoggedIn();

    if (success) {
      if (idp.vhostlessFromQuery(location.search) && (location.state?.from?.pathname ?? '/') === '/') {
        auth.logInToVhost();
      } else {
        history.replace(location.state.from);
      }
    }

    return { success };
  },

  async loginWithToken({ history, location }) {
    await auth.loginWithTwoFactorToken({
      idpType: idp.typeFromQuery(location.search),
      ...(location.state?.twoFactorState ?? {}),
    });

    const success = auth.isLoggedIn();

    if (success) {
      if (idp.vhostlessFromQuery(location.search) && (location.state?.from?.pathname ?? '/') === '/') {
        auth.logInToVhost();
      } else {
        history.replace(location.state.from);
      }
    }

    return { success };
  },

  async request({ location, twoFactorMethod }) {
    const { search, state } = location;
    const idpType = idp.typeFromQuery(search);
    const { twoFactorState } = state;

    await auth.requestTwoFactor({
      ...twoFactorState,
      idpType,
      twoFactorMethod,
    });
  }
};
