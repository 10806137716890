import React from 'react';
import { Redirect } from 'react-router-dom';

import { Alert, Button, Card, CardBody, CardHeader, CardTitle } from '@appfolio/react-gears';

import application from '../lib/application';
import { NotFoundError } from '../lib/errors';
import rollbar from '../lib/rollbar';

import ApplicationForm from './ApplicationForm';
import { ErrorMessage } from './FlashMessage';

class ApplicationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      app: null,
      loading: false,
      saved: false,
      redirectTo: null,
      error: '',
      errors: {},
    };

    this.updateApp = this.updateApp.bind(this);
    this.deleteApp = this.deleteApp.bind(this);
  }

  async componentDidMount() {
    const { match } = this.props;
    const appId = match.params.id;

    try {
      const app = await application.get(appId);
      app.scopes = app.scopes.join(' ');
      this.setState({ app });
    } catch (err) {
      if (err instanceof NotFoundError) {
        this.setState({ redirectTo: '/404' });
      } else {
        this.setState({ error: err.message });
      }
    }
  }

  async updateApp(e, appData) {
    this.setState({ loading: true });
    try {
      const app = await application.update(appData);
      app.scopes = app.scopes.join(' ');
      this.setState({ app, saved: true, errors: {} });
    } catch ({ errors }) {
      this.setState({ errors });
      rollbar.error('ApplicationPage: Error calling updateApp', errors);
    } finally {
      this.setState({ loading: false });
    }
  }

  async deleteApp() {
    this.setState({ loading: true });
    try {
      await application.destroy(this.state.app.client_id);
      this.setState({ redirectTo: '/applications' });
    } catch (err) {
      this.setState({ error: 'Could Not Delete' });
      rollbar.error('ApplicationPage: Error calling deleteApp', err);
    }
  }

  render() {
    const { app, redirectTo } = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    return app ?
      <Card>
        <CardHeader>
          <Button color="danger" className="float-end" onClick={this.deleteApp}>Delete</Button>
          <CardTitle>Edit Application</CardTitle>
          <ErrorMessage message={this.state.error} />
        </CardHeader>
        <CardBody>
          {this.state.saved && <Alert color="success">Application Saved</Alert>}
          <ApplicationForm
            readOnly={this.state.loading}
            onSubmit={this.updateApp}
            errors={this.state.errors}
            {...this.state.app}
          />
        </CardBody>
      </Card> :
      <div className="spinner" />;
  }
}

export default ApplicationPage;
