import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Table } from '@appfolio/react-gears';

const ApplicationList = ({ items }) => (
  items.length ?
    <Table>
      <thead>
        <tr>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <tr key={item.client_id}>
            <td className="pt-3 pb-3">
              <Link to={`applications/${item.client_id}`}>{item.name}</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table> :
    <span>There aren&#8217;t any applications</span>
);

ApplicationList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    client_id: PropTypes.string,
    name: PropTypes.string,
  })),
};

ApplicationList.defaultProps = {
  items: [],
};

export default ApplicationList;
