export default {
  partnerportal: {
    logoUrl: 'https://public.cdn.appfolio.com/public/images/partners/appfolio_developer_space_login.svg',
    logoAlt: 'AppFolio Developer Space Logo',
  },
  partnerportal_admin: {
    logoUrl: 'https://public.cdn.appfolio.com/public/images/partners/appfolio_partners_admin_login_white.svg',
    logoAlt: 'AppFolio Partner Portal Admin Logo',
  },
  partnerportal_internal: {
    logoUrl: 'https://public.cdn.appfolio.com/public/images/partners/appfolio_developer_space_internal_login.svg',
    logoAlt: 'AppFolio Developer Space Internal Login Logo',
  },
  developerspace: {
    logoUrl: 'https://public.cdn.appfolio.com/public/images/partners/appfolio_developer_space_login.svg',
    logoAlt: 'AppFolio Developer Space Logo',
  },
  developerspace_internal: {
    logoUrl: 'https://public.cdn.appfolio.com/public/images/partners/appfolio_developer_space_internal_login.svg',
    logoAlt: 'AppFolio Developer Space Internal Login Logo',
  },
  vendor: {
    logoUrl: 'https://vendor.qa.appfolio.com/images/vendor_portal_logo.svg',
    logoAlt: 'AppFolio Vendor Portal Logo',
  },
  default: {
    logoUrl: 'https://public.cdn.appfolio.com/public/images/apm-secondary-white.svg',
    logoAlt: 'AppFolio Logo',
  },
};
