import React from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import auth from '../../lib/auth';
import helpers from '../../lib/helpers';
import rollbar from '../../lib/rollbar';
import LoginForm from './LoginForm';
import { ErrorMessage, SuccessMessage } from '../FlashMessage';
import { VhostRequiredError, UnauthorizedError } from '../../lib/errors';
import LoginFormFooter from './LoginFormFooter';
import RequestAccessLinkBtn from './AccessLink/RequestAccessLinkBtn';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.from = (props.location.state || { from: { pathname: '/' } }).from;
    this.queryParams = helpers.parseQueryString(props.location.search);
    this.state = {
      error: '',
      loading: false,
      failedAttempts: 0,
      username: '',
    };

    this.logIn = this.logIn.bind(this);
    this.setUsername = this.setUsername.bind(this);

    const sessionStorageAuthRedirect = window.sessionStorage.getItem('authRedirect');
    const search = JSON.parse(sessionStorageAuthRedirect)?.search;
    const queryString = qs.parse(search, { ignoreQueryPrefix: true });
    const fullRedirectUri = queryString.redirect_uri;
    try {
      this.redirectUri = new URL(fullRedirectUri).origin;
    } catch {
      this.redirectUri = fullRedirectUri;
    }
  }

  setUsername = (user) => {
    this.setState({
      username: user,
    });
  }

  async logIn(credentials) {
    this.setState({ loading: true });
    const { match } = this.props;

    try {
      const data = await auth.logIn(credentials);

      if (data.reverificationRequired) {
        return window.location.replace(`https://${data.vhost}`);
      }

      if (data.twoFactorSession) {
        if (data.phoneNumber || data.totpMfa || data.email2Fa) {
          // allow changing phone number for email 2FA deprecation
          if (data.email2Fa) {
            data.phoneNumber = undefined;
          }
          return this.redirectTo({
            pathname: `${match.url}/mfa`,
            state: { twoFactorState: data },
          });
        }
        return this.redirectTo({
          pathname: `${match.url}/mfa_enrollment`,
          state: { twoFactorState: data },
        });
      }

      if (data.constructor === Object) {
        return this.redirectTo({
          pathname: `${match.url}/accounts`,
          state: { accounts: data },
        });
      }

      if (this.queryParams.vhostless && this.from.pathname === '/') {
        return auth.logInToVhost();
      }

      return this.redirectTo({
        pathname: `${match.url}`,
      });
    } catch (err) {
      rollbar.warn(`LoginPage: Error calling login: ${err.message}`, err);

      if (err instanceof VhostRequiredError) {
        return this.setState({
          error: 'Invalid email or password',
          loading: false,
          failedAttempts: this.state.failedAttempts + 1,
        });
      }

      if (err instanceof UnauthorizedError) {
        return this.setState({
          error: 'Invalid email or password',
          loading: false,
        });
      }

      return this.setState({
        error: err.message,
        loading: false,
      });
    }
  }

  redirectTo = ({ state, ...newLocation }) => {
    const { history, location } = this.props;

    return history.replace({
      ...newLocation,
      search: location.search,
      state: { ...state, from: this.from },
    });
  }

  render() {
    const { loading } = this.state;
    const { idpType, vhostless, email, confirmed } = this.queryParams;
    const { match, location } = this.props;

    return (
      <div className="Page">
        {confirmed && <SuccessMessage message="Thanks for confirming your email!" duration={3000} />}
        <h1 className="mb-4">Sign In</h1>
        {this.redirectUri && <p className="sign-into">You are signing into {this.redirectUri}</p>}
        {idpType === 'vendor' && (
          <RequestAccessLinkBtn
            match={match}
            username={this.state.username}
            redirectTo={this.redirectTo}
          />
        )}
        <ErrorMessage message={this.state.error} />
        <LoginForm
          readOnly={loading}
          idpType={idpType}
          vhostless={vhostless === 'true'}
          email={email}
          failedAttempts={this.state.failedAttempts}
          onSubmit={this.logIn}
          saveUsername={this.setUsername}
        />
        <Link
          to={{
            pathname: `${match.url}/forgot_password`,
            search: location.search,
            state: {
              from: match.url,
              username: this.state.username,
            },
          }}
        >
          Forgot your password?
        </Link>
        <LoginFormFooter idpType={idpType} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.object,
    }),
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default LoginPage;
