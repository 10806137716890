import React from 'react';
import PropTypes from 'prop-types';
import { BoundForm, BoundFormRow, Button, ButtonToolbar, FormChoice } from '@appfolio/react-gears';

const parseErrors = errors =>
  Object.assign({}, ...Object.keys(errors).map((field) => {
    return { [field]: errors[field].join(', ') };
  }));

const ApplicationForm = ({ errors, onSubmit, readOnly, ...fields }) => (
  <BoundForm object={fields} onSubmit={onSubmit} errors={parseErrors(errors)}>
    <BoundFormRow name="name" label="Application Name" stacked readOnly={readOnly} />
    {fields.client_id && <BoundFormRow name="client_id" label="Client ID" stacked readOnly />}
    {fields.client_id &&
      <BoundFormRow name="client_secret" label="Client Secret" stacked readOnly />}
    <BoundFormRow name="ttl" label="TTL in Minutes" stacked readOnly={readOnly} />
    <BoundFormRow name="refresh_ttl" label="Refresh TTL in Minutes" stacked readOnly={readOnly} />
    <BoundFormRow
      name="auto_authorize"
      label="Auto Authorize"
      stacked
      readOnly={readOnly}
      type="select"
    >
      <FormChoice value="false">Disabled</FormChoice>
      <FormChoice value="true">Enabled</FormChoice>
    </BoundFormRow>
    <BoundFormRow
      name="redirect_uri"
      type="textarea"
      label="Redirect URI"
      stacked
      readOnly={readOnly}
      hint="Multiple Redirect URIs are supported. Enter each one on a new line."
    />
    <BoundFormRow
      name="scopes"
      label="Scopes"
      stacked
      readOnly={readOnly}
      hint={'`write` is the default scope. If you do not have `write` included, make sure you pass your application scope using "scope=blah" as a param when requesting a token. Please use a space to separate your scopes.'}
    />
    <BoundFormRow
      name="identity_provider_type_id"
      label="Select Identity Provider Type"
      stacked
      readOnly={readOnly}
      type="select"
    >
      <FormChoice value="" />
      <FormChoice value="1">TPortal</FormChoice>
      <FormChoice value="2">Property</FormChoice>
      <FormChoice value="3">OPortal</FormChoice>
      <FormChoice value="4">Wildcard</FormChoice>
      <FormChoice value="5">Internal</FormChoice>
      <FormChoice value="6">Vendor</FormChoice>
      <FormChoice value="7">Partner Api</FormChoice>
      <FormChoice value="8">Identity</FormChoice>
    </BoundFormRow>
    <BoundFormRow
      label="Select Grant Type"
      name="grant_type"
      stacked
      readOnly={readOnly}
      type="select"
    >
      <FormChoice value="authorization_code">Authorization Code</FormChoice>
      <FormChoice value="password">Password</FormChoice>
      <FormChoice value="implicit">Implicit</FormChoice>
      <FormChoice value="client_credentials">Client Credentials</FormChoice>
    </BoundFormRow>
    <BoundFormRow
      name="confidential"
      label="Confidential "
      stacked
      readOnly={readOnly}
      hint="Password OR implicit apps should be confidential if they are using refresh tokens"
      type="select"
    >
      <FormChoice value="false">False</FormChoice>
      <FormChoice value="true">True</FormChoice>
    </BoundFormRow>
    <ButtonToolbar>
      <Button
        type="submit"
        color="primary"
        disabled={readOnly}
        className="js-save-btn"
      >
        Save
      </Button>
      <Button
        color="secondary"
        onClick={() => window.location.assign('/applications')}
        className="js-cancel-btn"
      >
        Cancel
      </Button>
    </ButtonToolbar>
  </BoundForm>
);

ApplicationForm.propTypes = {
  name: PropTypes.string,
  ttl: PropTypes.number,
  refresh_ttl: PropTypes.number,
  auto_authorize: PropTypes.bool,
  redirect_uri: PropTypes.string,
  scopes: PropTypes.string,
  confidential: PropTypes.bool,
  identity_provider_type_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  grant_type: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
};

ApplicationForm.defaultProps = {
  name: '',
  ttl: 120,
  refresh_ttl: 1440,
  auto_authorize: false,
  redirect_uri: '',
  scopes: 'write',
  confidential: true,
  identity_provider_type_id: '',
  grant_type: 'authorization_code',
  readOnly: false,
  errors: {},
};

export default ApplicationForm;
