import qs from 'qs';
import api from './api';

async function perform(method, params = {}) {
  if (!params.client_id) {
    throw new Error('Missing client_id');
  }

  const { response_type: responseType, ...query } = params;

  const body = {
    response_type: responseType || 'code',
    ...query,
  };

  const path = `/oauth/authorize${method === 'GET' ? `?${qs.stringify(body)}` : ''}`;

  const { data } = await api.request(path, {
    method,
    authenticated: true,
    body: method === 'GET' ? '' : body,
  });

  return {
    redirectURI: data.redirect_uri,
    name: data.name,
  };
}

export default {
  check(params) {
    return perform('GET', params);
  },
  approve(params) {
    return perform('POST', params);
  },
  deny(params) {
    return perform('DELETE', params);
  },
};
