import React from 'react';
import PropTypes from 'prop-types';
import { Popover, PopoverTitle, PopoverBody, FormRow } from '@appfolio/react-gears';

function urlHelp(idpType) {
  const content = {
    tportal: {
      text: 'Your database is the first part of your portal URL: ',
      path: '/connect',
    },
    oportal: {
      text: 'Your database is the first part of your portal URL: ',
      path: '/portal',
    },
    property: {
      text: 'Your database is the first part of your AppFolio URL: ',
      path: '/',
    },
  };

  return (
    <PopoverBody>
      {content[idpType].text}
      <code>
        https://
        <b>mydatabase</b>
        {`.appfolio.com${content[idpType].path}`}
      </code>
    </PopoverBody>
  );
}

class VHostFormRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
    };

    this.toggleHelp = this.toggleHelp.bind(this);
  }

  toggleHelp(popoverOpen) {
    this.setState({ popoverOpen });
  }

  render() {
    const { idpType, ...props } = this.props;

    return (
      <div>
        <FormRow
          id="vhostInput"
          name="vhost"
          label="Database"
          labelSize="lg"
          size="lg"
          placeholder="mydatabase"
          stacked
          onFocus={() => this.toggleHelp(true)}
          onBlur={() => this.toggleHelp(false)}
          {...props}
        />
        <Popover placement="bottom" target="vhostInput" isOpen={this.state.popoverOpen}>
          <PopoverTitle className="font-weight-normal">What&#39;s a database?</PopoverTitle>
          {urlHelp(idpType)}
        </Popover>
      </div>
    );
  }
}

VHostFormRow.propTypes = {
  idpType: PropTypes.oneOf(['tportal', 'property', 'oportal']).isRequired,
};

export default VHostFormRow;
