import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem } from '@appfolio/react-gears';

const Navigation = () => (
  <Navbar
    className="mb-4"
    color="primary"
    dark
    expand="sm"
    tag="nav"
  >
    <Container style={{ maxWidth: '992px' }}>
      <Nav navbar>
        <NavItem>
          <NavLink to="/applications" activeClassName="active" className="nav-link">
            Applications
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/basic/clients" activeClassName="active" className="nav-link">
            External Clients
          </NavLink>
        </NavItem>
        <NavItem className="ms-auto">
          <NavLink to="/sign_out" className="nav-link">Logout</NavLink>
        </NavItem>
      </Nav>
    </Container>
  </Navbar>
);

export default Navigation;
