import React from 'react';
import { Card, CardBody, CardHeader, CardTitle } from '@appfolio/react-gears';

const NotFoundPage = () => (
  <Card className="text-center">
    <CardHeader>
      <CardTitle>404 - Page not found</CardTitle>
    </CardHeader>
    <CardBody>
      <p>Sorry, that page does not exist.</p>
    </CardBody>
  </Card>
);

export default NotFoundPage;
